<template>
  <div>
    <span class="text_3">云商城</span>
    <span class="text_4"
      >提高企业生产效率和管理水平&nbsp;创造更多的商业机会和价值空间</span
    >
    
    <img
      class="label_1"
      referrerpolicy="no-referrer"
      src="./assets/img/pshpeqdv7jetpaig6nd1lltjh4e0xfmx3584accf186-0079-490a-a80b-34113eb2e33b.png"
    />
    <div class="group_3 flex-row">
      <div class="group_4 flex-col">
        <span class="text_5">云超市</span>
        <span class="text_6">电机配件和成品展示</span>
        <div class="box_3 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps1ydgbpwdi2gfpng52a2r7op4v0na2j4e9fc80c60-4654-4093-b439-bb66203d1ba4.png"
            />
            <span class="text-group_1">新能源专区</span>
          </div>
          <div class="image-text_2 flex-row justify-between">
            <img
              class="thumbnail_2"
              referrerpolicy="no-referrer"
              src="./assets/img/ps5u5ihhw42qr2x0dcyxxzde79fjwcirb67f793c302-3f15-48fc-aa42-bd5ca96d862a.png"
            />
            <span class="text-group_2">电工材料专区</span>
          </div>
        </div>
        <div class="box_4 flex-row justify-between">
          <div class="image-text_3 flex-row justify-between">
            <img
              class="thumbnail_3"
              referrerpolicy="no-referrer"
              src="./assets/img/psr2w7j9f2br2zxq2f783grrbwsaf0w5cbcdc800a-1198-4d56-a93e-6d1ad62ee793.png"
            />
            <span class="text-group_3">外贸专区</span>
          </div>
          <div class="image-text_4 flex-row justify-between">
            <img
              class="thumbnail_4"
              referrerpolicy="no-referrer"
              src="./assets/img/psbvav1hqsg8o3s33418klnt8o2io3w4wa49643c3d-69bc-4f49-b5a2-18026709f2ec.png"
            />
            <span class="text-group_4">电机行业专区</span>
          </div>
        </div>
        <div class="image-text_5 flex-row justify-between">
          <img
            class="thumbnail_5"
            referrerpolicy="no-referrer"
            src="./assets/img/ps6ztr7cadd7t85jyghj453mwimcv5po98840b28ef7-894e-4d28-98b3-bcdac8c09c3a.png"
          />
          <span class="text-group_5">海外资源专区</span>
        </div>
        <div class="image-text_6 flex-row justify-between">
          <img
            class="thumbnail_6"
            referrerpolicy="no-referrer"
            src="./assets/img/ps0rn7kt57alf7y64h24x6agukmc6fwl5ed11b12bc-1c7b-47d5-8895-947ab6ac9de6.png"
          />
          <span class="text-group_6">工业汽车专区</span>
        </div>
        <div class="text-wrapper_1 flex-col">
          <span class="text_7">立即查看</span>
        </div>
      </div>
      <div class="text-wrapper_2 flex-col">
        <span class="text_8">云集采</span>
        <span class="text_9">设备、原材料采集中心</span>
      </div>
      <div class="text-wrapper_3 flex-col">
        <span class="text_10">云供求</span>
        <span class="text_11">供求资源信息共享</span>
      </div>
      <div class="text-wrapper_4 flex-col">
        <span class="text_12">云展厅</span>
        <span class="paragraph_1">
          全息技术沉浸式展示产
          <br />
          品，形成云目录实现产
          <br />
          品目录营销
        </span>
      </div>
      <div class="text-wrapper_5 flex-col">
        <span class="text_13">云物流</span>
        <span class="paragraph_2">
          与央企物流集团合作，
          <br />
          利用其全国中心仓和城
          <br />
          市分仓为企业提供及时、
          <br />
          高效、优质服务
        </span>
      </div>
      <div class="text-wrapper_6 flex-col">
        <span class="text_14">云签约</span>
        <span class="paragraph_3">
          基于互联网技术的在线
          <br />
          签约平台，可以提供高
          <br />
          效、安全、便捷的签约
          <br />
          服务
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />