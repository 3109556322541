import request from '@/utils/request'
export default {
  // 以下是：首页 api
  initPageData() {
    return request({
      url: 'host/hostSupplyDemand/initPageData',
      method: 'get'
    })
  },
  list(data) {
    return request({
      url: 'host/hostSupplyDemand/list',
      method: 'post',
      data
    })
  },
  info(data){
    return request({
      url:'host/hostSupplyDemand/info',
      method:"post",
      data
    })
  },
  infoComments(data){
    return request({
      url:'host/hostSupplyDemand/infoComments',
      method:"post",
      data
    })
  }
}