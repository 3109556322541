<template>
  <div class="page flex-col">
    <div class="group_1 flex-row">
      <div id="content" class="box_1 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_1">Cloud&nbsp;Mall</span>
          <span class="text_2">云商城</span>
        </div>
        <div class="box_2 flex-row justify-between">
          <div class="group_2 flex-col">
            <div class="text-wrapper_1 flex-col">
              <span class="text_3">云超市</span>
            </div>
            <span class="text_4">服务项目介绍</span>
            <span class="text_5">轴承配件和成品展示</span>
            <div class="text-wrapper_2 flex-col">
              <span class="text_6">立即了解</span>
            </div>
          </div>
          <div class="group_3 flex-col">
            <div class="text-wrapper_3 flex-col">
              <span class="text_7">云集采</span>
            </div>
            <span class="text_8">服务项目介绍</span>
            <span class="text_9">
              云集采是精一云创工业互联网平台提供的集中采购中心，针对轴承企业打造的专属集中式采购平台，解决企业面临的，成本高、价格波动大、低效率、质量不稳定等痛点。依托国企背景，整合企业资源，打通上游供应链，由供应商定点发货，企业0仓储成本，帮助企业构建智能化商业生态圈。
            </span>
            <div class="text-wrapper_4 flex-col">
              <span class="text_10">
                <a href="/lanhu_yunjicai" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
        </div>
        <div class="box_3 flex-row justify-between">
          <div class="box_4 flex-col">
            <div class="text-wrapper_5 flex-col">
              <span class="text_11">云供求</span>
            </div>
            <span class="text_12">服务项目介绍</span>
            <span class="paragraph_1">
              云供求是精一云创工业互联网平台上实现供求信息的交换，通过数据挖掘、分析和智能匹配等技术手段，为用户提供高效、便捷、精准的供求对接服务。随着数字技术的不断发展和应用，云供求作为一种全新的商业模式和服务方式，在现代经济中逐渐崭露头角。
              <br />
            </span>
            <div class="text-wrapper_6 flex-col">
              <span class="text_13">
                <a href="/lanhu_yungongqiu" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
          <div class="box_5 flex-col">
            <div class="text-wrapper_7 flex-col">
              <span class="text_14">云展厅</span>
            </div>
            <span class="text_15">服务项目介绍</span>
            <span class="text_16">
              云展厅是一款创新的VR在线展览展厅，通过利用3D数字化联动能力，实现展会沉浸式体验，还原展厅内场景真实空间，为传统展会向数字化转型赋能。与传统展会相比，云展厅具有更多的优势和特点，让参展更加便捷、高效，同时也让参观更加灵活、自由。
            </span>
            <div class="text-wrapper_8 flex-col">
              <span class="text_17">
                <a href="/lanhu_yunzhanting" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
        </div>
        <div class="box_6 flex-row justify-between">
          <div class="group_4 flex-col">
            <div class="text-wrapper_9 flex-col">
              <span class="text_18">云物流</span>
            </div>
            <span class="text_19">服务项目介绍</span>
            <span class="text_20">
              通过互联网技术和物流技术的融合，提供一站式物流服务，为企业提供高效、便捷、安全的物流管理解决方案。同时通过物联网、云计算、大数据等新技术手段，实现对仓储装备、仓库物流设施等信息的实时监测和价值挖掘，将仓储、运输、物流等多个环节的信息进行集成、整合和优化，为企业提供了更加全面和精准的仓储管理服务。
            </span>
            <div class="text-wrapper_10 flex-col">
              <span class="text_21">
                <a href="/lanhu_yunwuliu" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
          <div class="group_5 flex-col">
            <div class="text-wrapper_11 flex-col">
              <span class="text_22">云签约</span>
            </div>
            <span class="text_23">服务项目介绍</span>
            <span class="paragraph_2">
              基于互联网技术的在线签约平台，可以提供高效、安全、便捷的签约服务。实现跨地域、跨时空、跨边界的在线签署功能。帮助企业提高工作效率、降低成本。
              <br />
              云签约板块可以对包括内外部员工、供应商、客户、合作伙伴等多方进行签约，签订各种合同、协议、声明、授权书等多种业务文书。通过云签约模块，企业可以将签约流程电子化、规范化，从而降低成本、提高效率。
            </span>
            <div class="text-wrapper_12 flex-col">
              <span class="text_24">
                <a href="/lanhu_yunqianyue" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
        </div>
        <fotter :showTag="true" />
      </div>
      <div class="box_9 flex-col">
        <!-- <div class="box_10 flex-row">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/psgk6zqoexieff8ak42ar15vehcirzrk9qd6060c1bd-fcf7-47f8-9ea1-647bcc69d00b.png"
          />
          <span class="text_33"
            >首页&nbsp;云工厂&nbsp;云商城&nbsp;云服务&nbsp;关于我们</span
          >
          <span class="text_34">登录&nbsp;&nbsp;|&nbsp;&nbsp;注册</span>
        </div> -->
        <headerCom />
        <!-- 
          <div class="box_11 flex-col">
            <div class="block_1 flex-col">
              </div>
            </div> 
        -->
        <span class="text_35">云商城</span>
        <span class="text_36"
          >整合不同环节的系统企业服务&nbsp;实现更高效的生产和管理</span
        >
   
      </div>
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />