<template>
    <div>
        <el-card class="supply-card" shadow="hover">
            <div class="card-container">
                <el-row :gutter="20" style="width:100%">
                    <el-col :span="4">
                        <!-- 用户头像 -->
                        <div class="avatar">
                            <img :src="$url(formData.icon)" alt="User Avatar" width="60" height="60">
                        </div>
                    </el-col>
                    <el-col :span="20">
                        <!-- 用户信息 -->
                        <div class="info">

                            <span class="name">{{ formData.name }} / <span class="position">{{
                                formData.position }}</span></span>

                            <div class="companyName">{{ formData.companyName }}</div>

                            <div class="info-container">
                                <div class="info-item"><img src="./assets/img/phone.png"
                                        style="margin-right: 2px;margin-top:5px;margin-bottom:5px;"><span
                                        style="position: relative;top:-8px;right:-10px; font-size: 14px;">{{ formData.phone
                                        }}</span>
                                </div>
                                <div class="info-item"><img src="./assets/img/email.png"
                                        style="margin-right: 2px;margin-top:5px;margin-bottom:5px;"><span
                                        style="position: relative;top:-8px;right:-10px; font-size: 14px;">{{ formData.email
                                        }}</span>
                                </div>
                                <div class="info-item"><img src="./assets/img/address.png"
                                        style="margin-right: 2px;margin-top:5px;margin-bottom:5px;"><span
                                        style="position: relative;top:-8px;right:-10px; font-size: 14px;">{{
                                            formData.address }}</span>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div v-if="formData.length && formData.pictures.length > 0" class="container-row pictures-container">
                <div v-for="(item, index) in pictures.length > 3 ? getThreePictures(pictures) : pictures" :key="index">
                    <img :src="$url(item.url)" />
                    <div v-if="(index === 2 && pictures.length > 3)" class="expend-img-mask">
                        <span>+{{ pictures.length - 3 }}</span>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>
  
<script>
export default {
    name: 'card',
    props: {
        formData: {}
    },
    methods: {
        getThreePictures: function (array) {
            return array.slice(0, 3)
        },
    }
}
</script>
  
<style scoped>
.supply-card {
    margin: 0.1479rem;
    height: 10.0rem;
}

.card-container {
    display: flex;
    align-items: center;
    padding: 0.4735rem;
}

.avatar img {
    width: 1.7757rem;
    height: 1.7757rem;
    border-radius: 50%;
    object-fit: cover;
}

.info {
    display: flex;
    flex-direction: column;
    margin-left: 0.8878rem;
}

.name {
    font-size: 0.5919rem;
    font-weight: bold;
    margin: 0;
}

.position {
    font-size: 0.4735rem;
    font-weight: 200;
}

.companyName {
    margin-top: 0.5919rem;
    margin-bottom: 0.5919rem;
    height: 0.8rem;
    width: 6.5rem;
    font-size: 0.4339rem;
    line-height: 0.5919rem;
}

.info-container {
    display: flex;
    flex-direction: column;
    font-size: 0.3551rem;
    color: #909399;
    margin-top: 0.1183rem;
}

.info-container>.info-item {
    margin-top: 0.2959rem;
    margin-bottom: 0.2959rem;
    width: 6.5rem;
}

.date {
    display: block;
    white-space: nowrap;
}

.address {
    display: block;
    white-space: nowrap;
    width: 5.9190rem;
}


.image img {
    max-width: 100%;
    height: 1.0654rem;
    object-fit: cover;
}


div.pictures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.pictures-container>div {
    position: relative;
    width: 33.33%;
    height: 0;
    padding-bottom: 33.33%;

}

.pictures-container>div>img {
    display: block;
    position: absolute;
    width: 96%;
    height: 96%;
    top: 2%;
    left: 2%;
    border: 1px solid #efefef;
    box-sizing: border-box;
}

.container-row {
    flex-wrap: wrap;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

div.expend-img-mask {
    display: flex;
    background: rgba(0, 0, 0, .5);
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    box-sizing: border-box;
    z-index: 9;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    justify-content: center;
    align-items: center;
}

div.expend-img-mask>span {
    font-size: 20px;
    color: #fff;
    line-height: 20px;
}
</style>
  