<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3S">关于我们</span>
        <span class="text_4S">打造一流的轴承行业的工业互联网平台和专业轴承云商城！</span>
      </div>
      <div class="block_2 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_4">Platform&nbsp;advantages</span>
          <span class="text_5">平台优势</span>
        </div>
        <div class="group_1 flex-row">
          <div class="group_2 flex-col">
            <img class="image_3" referrerpolicy="no-referrer"
              src="./assets/img/ps63zazfn9esf3lhvesz78sdeust0w5h1ywff27d476-980b-4b58-8875-255a73892ad6.png" />
            <span class="text_6">综合性平台</span>
            <span class="text_7">
              该平台就是为轴承系统企业提供一种完整的、从项目设计到运营管理，再到后续维护服务全周期解决方案。
            </span>
          </div>
          <div class="group_3 flex-col">
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/pst35d7q3q9n38tk8jqenoe9e9oocbvhmmd53ad309-db21-4988-94ce-adf7a0e14ec8.png" />
            <span class="text_8">企业精准服务</span>
            <span class="text_9">集结万名业内专家提供云咨询服务，制定技术、生产等环节专项解决方案</span>
          </div>
          <div class="group_4 flex-col">
            <img class="image_5" referrerpolicy="no-referrer"
              src="./assets/img/psetnitrx922pfsnjxhsz8bq98i56plq5a98a424476-fba0-4e43-b88d-85b840a13680.png" />
            <span class="text_10">闭环产业链</span>
            <span class="text_11">
              通过云集采、云商城和云物流等服务，可以打造行业闭环产业链，形成完整的设计、采购、生产、销售、售后等链条，更好地服务于客户的需求。
            </span>
          </div>
          <div class="group_5 flex-col">
            <img class="image_6" referrerpolicy="no-referrer"
              src="./assets/img/pst9qdtsa1f5snyd2u3xh5eqp39eeclyx27993187-5fae-44d6-ba47-1907c0e17bb8.png" />
            <span class="text_12">全周期解决方案</span>
            <span class="text_13">从项目设计开始到项目实施、企业运营管理、云商城和云服务等方方面面的全周期服务。</span>
          </div>
        </div>

      </div>
      <div id="content" class="block_3 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_14">ABOUT&nbsp;US</span>
          <span class="text_15">关于我们</span>
        </div>
        <div class="text-wrapper_3">
          <span class="text_16">
            天恩璐集团协助瓦房店市政府、瓦房店轴承协会和轴承企业在中石化易派客电子商务平台上开发建设“易派客瓦房店轴承云商城”，提供产品上线服务，帮助企业通过行业认证和企业认证，拓宽营销渠道。
          </span>
          <span class="text_17">同时成立了大连精一云创供应链有限公司，为瓦房店企业专项提供集采服务。</span>
          <span class="text_18">

          </span>
        </div>
        <span class="text_19">
          天恩璐集团投资500万元成为了国家标识解析二级节点运营单位，开发基于标识解析的定制化解决方案，实现企业数字化经营和发展，构建壹码通联工业互联网平台，为企业提供全流程及产品全生命周期定制化服务。大连精一云创供应链有限公司是天恩璐集团的全资子公司。专注于为瓦房店轴承行业提供一站式工业互联网平台服务，致力于以国家标识解析二级节点运营为基础、工业互联网和数字化科技为手段，在瓦房店市打造一流的轴承行业的工业互联网平台和专业轴承云商城。
        </span>
        <img class="image_9" referrerpolicy="no-referrer"
          src="./assets/img/psg4mcwwvt1ttww4lv0tgqkd23hlqz07x2ric79c93d6-21bf-45a6-b4c2-81752591bac8.png" />
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pscnru9uuv0hnav1wvxqj7h78tmptyvkmcb357a38a-b208-4d32-8cca-801356af5af9",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pss1vgptgszakd1f0rrf3ahn83r8fkkqtmc2da6503-e70b-4a95-87a7-018dbfc29c78",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/ps0w9w5s15madi9vom3fzsal8qkdyh6u8j8pd7e557e9-f2c2-4e14-a248-891ff34b7e8b",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/psqdm7ll3f34mlvaohszjtjot5u3lewbr6abae153-48cc-49d9-96d9-4fc63a510615",
        },
      ],
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />