<template>
    <div class="box_2 flex-row">
        <div class="image-wrapper_3 flex-col">
            <img class="image_2" referrerpolicy="no-referrer" :src="$urlIdentity(formData.logo)" />
        </div>
        <span>
            <div class="text_28"> {{ formData.orgName }}</div>
            <div class="text_29"><i class="el-icon-location-outline"></i> {{ formData.orgAddr }} | <i
                    class="el-icon-phone"></i> {{ formData.contactPhone }} | <i class="el-icon-message"></i>{{
                        formData.contactEmail }}
            </div>
            <!-- <el-button class="button_30" type="primary" plain size="small" @click="to">企业介绍</el-button> -->
            <div class="image_box_top" v-if="formData.rotationChart">
                <div class="block_top" v-for="item in formData.rotationChart.split(',').slice(0, 3)" :key="item">
                    <el-image style="width: 5rem; height: 5rem" :src="$url(item)" :preview-src-list="[$url(item)]" fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
                <!-- <span v-if="formData.rotationChart.split(',').length > 3"
                    style="position: absolute;background: gray;width: 1.68rem;color: white;opacity: 0.7;font-size: 0.8rem;">
                </span> -->
            </div>
        </span>
        <!-- <div class="icon_01">
            <div class="label_2">

            </div>
            <div class="text_Z1">手机扫码</div>
        </div> -->

    </div>
</template>
  
<script>
import router from "@/router";
export default {
    name: 'websiteCard',
    props: {
        formData: {},
    },
    methods: {
        to() {
            router.push({ path: '/lanhu_identity_tenant' })
        }
    }
}
</script>
<style scoped lang="css" src="./assets/index.rem.css" />