var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_1 flex-row"},[_c('div',{staticClass:"section_1 flex-col"},[_vm._m(0),_c('div',{staticClass:"section_3 flex-col"},[_vm._m(1),_c('div',{staticClass:"image-wrapper_1 flex-row"},[_c('img',{staticClass:"image_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psr6j0pal51wqtdlwsqis1gu4bbjj7zpuh4608b848-a504-4627-b0c6-b98987571591.png")},on:{"click":_vm.leftClick}}),_vm._m(2),_c('img',{staticClass:"image_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/pscigvvxbxemoj2jsndwd1gp05s8gtdyugn506a74d79-a147-491b-93c1-454973fec04c.png")},on:{"click":_vm.rightClick}})]),_c('div',{staticClass:"box_3 flex-row"},[_c('div',{staticClass:"image-text_2 flex-col justify-between",on:{"click":function($event){return _vm.setValueByIndex(0, 1, 0)},"mouseenter":function($event){return _vm.setValueByIndex(0, 1, 0)}}},[_vm._m(3),_c('span',{staticClass:"text-group_2"},[_vm._v("云可研")])]),_c('div',{staticClass:"image-text_3 flex-col justify-between",on:{"click":function($event){return _vm.setValueByIndex(1, 1, 0)},"mouseenter":function($event){return _vm.setValueByIndex(1, 1, 0)}}},[_vm._m(4),_c('span',{staticClass:"text-group_3"},[_vm._v("云设计")])]),_c('div',{staticClass:"image-text_4 flex-col justify-between",on:{"click":function($event){return _vm.setValueByIndex(2, 1, 0)},"mouseenter":function($event){return _vm.setValueByIndex(2, 1, 0)}}},[_vm._m(5),_c('span',{staticClass:"text-group_4"},[_vm._v("云金融")])]),_c('div',{staticClass:"image-text_5 flex-col justify-between",on:{"click":function($event){return _vm.setValueByIndex(3, 1, 0)},"mouseenter":function($event){return _vm.setValueByIndex(3, 1, 0)}}},[_vm._m(6),_c('span',{staticClass:"text-group_5"},[_vm._v("云洽谈")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_2 flex-col"},[_c('div',{staticClass:"box_2 flex-row"},[_c('img',{staticClass:"label_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psi1czfk34n549nqb61wk9t8rqr9ee574b196fed5a-e9db-4c84-902e-345673d20cff.png")}}),_c('span',{staticClass:"text_1"},[_vm._v("云服务")])]),_c('span',{staticClass:"text_2"},[_vm._v(" 集结万名业内专家提供云咨询服务，制定技术、生产等环节专项解决方案，建立区域维修中心，对企业做好精准服务 ")]),_c('div',{staticClass:"image-text_1 flex-row justify-between"},[_c('a',{attrs:{"href":"/lanhu_yunfuwu"}},[_c('img',{staticClass:"label_2",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/ps19oh6ychwco7nbsjlgz92665ist0ugujkf7eed1b8-7673-4fa9-959d-fd2b5b122626.png")}})]),_c('span',{staticClass:"text-group_1"},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"/lanhu_yunfuwu"}},[_vm._v("立即探索")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hb5_title",staticStyle:{"position":"relative","padding-top":"2rem","padding-bottom":"4rem"},attrs:{"id":"hb5_title"}},[_c('div',{staticStyle:{"position":"absolute"}},[_c('span',{staticClass:"text_3"},[_vm._v("云可研")]),_c('span',{staticClass:"text_4"},[_vm._v("在线会议平台     企业项目洽谈协作")]),_c('a',{attrs:{"href":"/lanhu_yunkeyan"}},[_c('img',{staticClass:"label_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png")}})])]),_c('div',{staticStyle:{"position":"absolute"}},[_c('span',{staticClass:"text_3"},[_vm._v("云设计")]),_c('span',{staticClass:"text_4"},[_vm._v("产品 工艺 管理 售后全方位运营链设计")]),_c('a',{attrs:{"href":"/lanhu_yunsheji"}},[_c('img',{staticClass:"label_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png")}})])]),_c('div',{staticStyle:{"position":"absolute"}},[_c('span',{staticClass:"text_3"},[_vm._v("云金融")]),_c('span',{staticClass:"text_4"},[_vm._v("融资服务 信用服务 投融资服务 资金管理服务")]),_c('a',{attrs:{"href":"/lanhu_yunjinrong"}},[_c('img',{staticClass:"label_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png")}})])]),_c('div',{staticStyle:{"position":"absolute"}},[_c('span',{staticClass:"text_3"},[_vm._v("云洽谈")]),_c('span',{staticClass:"text_4"},[_vm._v("在线会议平台     企业项目洽谈协作")]),_c('a',{attrs:{"href":"/lanhu_yunqiatan"}},[_c('img',{staticClass:"label_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image_2",attrs:{"id":"hb5_img"}},[_c('img',{attrs:{"referrerpolicy":"no-referrer","src":require("./assets/loopImg/云可研.png")}}),_c('img',{attrs:{"referrerpolicy":"no-referrer","src":require("./assets/loopImg/云设计.png")}}),_c('img',{attrs:{"referrerpolicy":"no-referrer","src":require("./assets/loopImg/云金融.png")}}),_c('img',{attrs:{"referrerpolicy":"no-referrer","src":require("./assets/loopImg/云洽谈.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label_4",staticStyle:{"position":"relative"},attrs:{"id":"hb5_lb4"}},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psqxgp7bxfesbaz1po6r8m4nrz1uaijxh9ja169d2f3-375b-4e5e-8ee0-3fb477975724.png")}}),_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/yky_hover.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label_5",staticStyle:{"position":"relative"},attrs:{"id":"hb5_lb5"}},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/ysj.png")}}),_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/ysj_hover.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label_6",staticStyle:{"position":"relative"},attrs:{"id":"hb5_lb6"}},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psaxuz6yjiamaqpxvj2wlk684ne0qm7pnao370c1404-9424-4735-a2f9-6e6765d361ae.png")}}),_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/yjr_hover.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label_7",staticStyle:{"position":"relative"},attrs:{"id":"hb5_lb7"}},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psphn5b2mgv98fgagqxzsazlzid1yt2krgc3d4347de-4e5e-4fce-a2ac-fc9ccdec6187.png")}}),_c('img',{staticStyle:{"position":"absolute"},attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/yqt_hover.png")}})])
}]

export { render, staticRenderFns }