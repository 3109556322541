<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="box_1 flex-col">
        <!--
        <div class="block_2 flex-row">
          <img class="image_1" referrerpolicy="no-referrer"
            src="./assets/img/pspoodjnc8melw8whhk2pe9p9wpjdou05b849a22d-2f11-442f-a23b-1d9fcb72fa24.png" />
          <span class="text_1">首页&nbsp;云工厂&nbsp;云商城&nbsp;云服务&nbsp;关于我们</span>
          <span class="text_2">登录&nbsp;&nbsp;|&nbsp;&nbsp;注册</span>
        </div>
        -->
        <headerCom fontColor="color:white" />
        <span class="text_3">云物流</span>
        <span class="paragraph_1">
          与央企物流集团合作，利用其全国中心仓和城市分仓
          <br />
          为企业提供及时、高效、优质服务
        </span>
       
          <div class="block_5 flex-row justify-between" @click="$scrollToTop('content')" style="cursor: pointer;">
            <div class="image-wrapper_1 flex-col">
              <img class="thumbnail_1" referrerpolicy="no-referrer"
                src="./assets/img/psqjxe5fqb3c16jplix10ktgtuhpu1trc98ba285bb-6954-41b6-b3f8-7332ee3dedcc.png" />
            </div>
            <span class="text_4"> 立即探索</span>
          </div>
   
      </div>
      <div class="box_2 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_5">Main&nbsp;Services</span>
          <span class="text_6">主要服务</span>
        </div>
        <span class="text_7">
          工业互联网平台云物流板块可以帮助企业实现智能化、规范化的物流管理，帮助企业实现物流过程的可视化、可控化和信息化，对仓储管理过程的精细化、智能化和自动化，提高工作效率，减少人力成本、降低物流费用、提高了仓储管理的质量，提升物流服务的质量和提高客户满意度。
        </span>
        <div class="group_1 flex-row justify-between">
          <div class="group_2 flex-col">
            <img class="image_2" referrerpolicy="no-referrer"
              src="./assets/img/ps7ju0x263v99z20d1fiumclxru4rbiocxi93b98d15-283b-48eb-aaea-3c636f5bcd7f.png" />
            <span class="text_8">物流计划</span>
            <span class="text_9">可以根据生产计划、库存数据等信息，自动生成物流计划，提高物流效率。</span>
          </div>
          <div class="group_3 flex-col">
            <img class="image_3" referrerpolicy="no-referrer"
              src="./assets/img/psobo1d79scx9yzx76soaynlfeo6yeiz9x7b3273a8-251b-43aa-8e09-6d58368df802.png" />
            <span class="text_10">运输管理</span>
            <span class="text_11">可以对运输路线、时效、费用等进行管理，并对货物追踪和监控。</span>
          </div>
          <div class="group_4 flex-col">
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/psxi1gami6gc6qzoh1jntdbfqg81alt5pj0b3db0a6-95cf-4e1b-8449-fbf2f4c85a30.png" />
            <span class="text_12">实时监控</span>
            <span class="text_13">可以通过互联网技术对物流过程进行实时监控，从而保证物品安全并提高物流效率。</span>
          </div>
          <div class="group_5 flex-col">
            <img class="image_5" referrerpolicy="no-referrer"
              src="./assets/img/pskrnlefchlygilnu34fw9kkrvai6cd904s5d5a9b60-4d77-4f6d-a7cc-ef822c1a2860.png" />
            <span class="text_14">数据管理</span>
            <span class="text_15">可以根据数据实时分析物流环节，以便更好地优化物流过程和提升效率。</span>
          </div>
        </div>
        <div class="group_6 flex-row justify-between">
          <div class="section_1 flex-col">
            <img class="image_6" referrerpolicy="no-referrer"
              src="./assets/img/psd2s20mpjrhu1bqoxkwl069vmfsfo18syl96ce2e38-1977-4ae8-85e6-c71907a1512c.png" />
            <span class="text_16">仓储管理</span>
            <span class="text_17">可以对仓库管理进行全流程控制，包括仓库定位、库位配置、入库、出库、库存管理等。</span>
          </div>
          <div class="section_2 flex-col">
            <img class="image_7" referrerpolicy="no-referrer"
              src="./assets/img/psayi4bt2t2qjpnbyd41mt48vusl0072ohc99c97f2-fb63-409c-a40f-475c20455679.png" />
            <span class="text_18">供应链协同</span>
            <span class="text_19">可以协助企业建立合理的供应链协同体系，从而更好地统筹物流、供应和销售等环节。</span>
          </div>
          <div class="section_3 flex-col">
            <img class="image_8" referrerpolicy="no-referrer"
              src="./assets/img/psyswmh4dr3oaey9pw0czctay4ahphc47n0a847cdf-8383-4f64-8a8a-1f2f83dea66d.png" />
            <span class="text_20">订单管理</span>
            <span class="text_21">可以对订单进行自动化管理，包括订单的接收、处理、分发、跟踪等。</span>
          </div>
          <div class="section_4 flex-col">
            <img class="image_9" referrerpolicy="no-referrer"
              src="./assets/img/pszx8vws8vzcgon0e7t3sb2abhebybxzlgud3c753b1-d879-48ba-8d80-13148049781e.png" />
            <span class="text_22">供应商管理</span>
            <span class="text_23">可以对供应商进行管理，方便企业与供应商的协作。</span>
          </div>
        </div>
      </div>
      <div class="box_3 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_24">Our&nbsp;Advantages</span>
          <span class="text_25">我们的优势</span>
        </div>
        <div class="group_7 flex-row justify-between">
          <img class="image_10" referrerpolicy="no-referrer"
            src="./assets/img/ps4xqhsym47jmgcd8qs52klge8dji0ljl4b160b6ef-153c-4973-92e4-ed2eaec57aa1.png" />
          <div class="box_4 flex-col justify-between">
            <div class="text-wrapper_1">
              <span class="paragraph_2">
                工业互联网平台云物流板块可以帮助企业
                <br />
              </span>
              <span class="text_26">实现智能化、规范化的物流管理</span>
            </div>
            <div class="group_8 flex-row justify-between">
              <div class="block_6 flex-col">
                <div class="group_9 flex-col"></div>
                <div class="group_10 flex-col"></div>
                <div class="group_11 flex-col"></div>
              </div>
              <span class="paragraph_3">
                帮助企业实现物流过程的可视化、可控化和信息化。
                <br />
                对仓储管理过程的精细化、智能化和自动化，提高工作效率，减少人力成本、降低物流费用。
                <br />
                提高了仓储管理的质量，提升物流服务的质量和提高客户满意度。
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="content" class="box_6 flex-col">
        <div class="text-group_4 flex-col justify-between">
          <span class="text_34">Cloud&nbsp;Services</span>
          <span class="text_35">云物流</span>
        </div>
        <div class="text-wrapper_5">
          <span class="text_36">
            基于互联网技术的物流管理平台，通过互联网技术和物流技术的融合，提供一站式物流服务，为企业提供
          </span>
          <span class="text_37">高效、便捷、安全的物流管理解决方案</span>
          <span class="paragraph_11">
            。
            <br />
          </span>
          <span class="text_38">
            同时通过物联网、云计算、大数据等新技术手段，实现对仓储装备、仓库物流设施等信息的实时监测和价值挖掘，将仓储、运输、物流等多个环节的信息进行集成、整合和优化，为企业提供了更加全面和精准的仓储管理服务。其核心特点是通过云技术实现信息的实时传递与共享，加强仓储管理，提升物流效率，降低运营成本，提高客户满意度。
          </span>
        </div>
        <img class="image_12" referrerpolicy="no-referrer"
          src="./assets/img/psbw85kyb4xbgl3luhd71s3aeljfsswmfj46ee734d-204b-40e9-9cde-7cddf6490a3e.png" />
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />