<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="section_19 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云供求</span>
        <span class="text_4">供求资源信息共享</span>
   
      </div>
      <div id="content" class="section_20 flex-col">
        <div class="text-group_34 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;supply&nbsp;and&nbsp;demand</span>
          <span class="text_7">云供求</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            数字化时代的新商机
            <br />
          </span>
          <span class="text_8">
            云供求是精一云创工业互联网平台上实现供求信息的交换，通过数据挖掘、分析和智能匹配等技术手段，为用户提供高效、便捷、精准的供求对接服务。随着数字技术的不断发展和应用，云供求作为一种全新的商业模式和服务方式，在现代经济中逐渐崭露头角。
          </span>
        </div>
        <div class="group_11 flex-row">
          <div class="text-wrapper_59 flex-col"><span class="text_10">打破传统</span></div>
          <span class="paragraph_2">
            在传统的供求市场中，信息不对称、地域限制、时间成本等问题一直存在，给供方和需方带来了很大的困扰和损失。
            <br />
            而云供求则通过将供求信息数字化、网络化，打破了地域和时间的限制，实现了精准匹配和高效交易，极大地提高了供求双方的效益和竞争力。
          </span>
        </div>
        <div class="box_31 flex-row">
          <div class="text-wrapper_60 flex-col"><span class="text_78">巨大机遇</span></div>
          <span class="paragraph_28">
            当前，随着大数据、人工智能、区块链等前沿技术的快速发展，云供求正面临着巨大的发展机遇。
            <br />
            一方面，越来越多的企业和个人开始意识到数字化转型的重要性，积极应用云供求平台提高自身的供求效率和降低成本；另一方面，云供求平台也在不断升级自身的技术和服务水平，为用户提供更加智能化、定制化、个性化的供求对接服务。
          </span>
        </div>
        <div class="box_33 flex-row">
          <div class="text-wrapper_61 flex-col"><span class="text_80">展望未来</span></div>
          <span class="text_81">
            未来，随着数字经济的发展和数字化转型的深入推进，云供求将成为越来越重要的商业模式和服务方式。
          </span>
        </div>
      </div>
      <div class="box_35 flex-col">
        <div class="group_75 flex-row">
          <div class="block_12 flex-col">
            <div class="box_64 flex-row">
              <el-tabs v-model="query.category" @tab-click="loadData(true)">
                <el-tab-pane label="最新" name="最新"></el-tab-pane>
                <el-tab-pane label="同城" name="同城"></el-tab-pane>
                <el-tab-pane label="推荐" name="推荐"></el-tab-pane>
              </el-tabs>
              <div class="text-wrapper_26 flex-col">
                <el-row :gutter="1">
                  <el-col :span="20">
                    <el-input v-model="query.keywords" style="width:100%" placeholder="请输入关键字" clearable
                      @change="loadData(true)"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="primary" size="medium" icon="el-icon-search" @click="loadData(true)"></el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="group_76 flex-row">
          <!--
          <div class="group_77 flex-col justify-between">
            <div class="group_40 flex-col">
              <div class="image-text_33 flex-row justify-between">
                <img
                  class="image_31"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psu8g0wqsvfq1k7kco9nalcz0rkqp30gbd100945a3-2a96-45dc-b7fb-04aaada5f588.png"
                />
                <div class="text-group_35 flex-col justify-between">
                  <span class="text_85">立即登陆</span>
                  <span class="text_86">登录后可发布信息</span>
                </div>
              </div>
              <img
                class="image_32"
                referrerpolicy="no-referrer"
                src="./assets/img/pstu7tyqu2xn9pcxc8ecvl6fa309t45guu623d3a96-9996-4eef-bc86-5c21243d11b0.png"
              />
            </div>
            <div class="group_41 flex-col">
              <div class="group_78 flex-row justify-between">
                <div class="group_79 flex-row">
                  <div class="block_14 flex-col"></div>
                  <img
                    class="thumbnail_28"
                    referrerpolicy="no-referrer"
                    src="./assets/img/pshju3cnpmppnfih9604zv68dp77t2cx1s71cca1e6b-eceb-493a-82d9-a96b9d009df8.png"
                  />
                </div>
                <div class="group_80 flex-col justify-between">
                  <span class="text_87">刘鑫</span>
                  <div class="group_44 flex-row">
                    <div class="image-text_34 flex-row justify-between">
                      <img
                        class="label_9"
                        referrerpolicy="no-referrer"
                        src="./assets/img/ps81lxsi3vhbirjse3h36w67sxers1f41i6ec4cbde-8cfe-4e81-add6-8e0c4aeac00b.png"
                      />
                      <span class="text-group_22">电子名片</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-wrapper_27 flex-col"><span class="text_88">信华信技术股份有限公司</span></div>
              <div class="block_15 flex-col">
                <div class="text-wrapper_28">
                  <span class="text_89">我发布的信息&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span class="text_90">&nbsp;&nbsp;20</span>
                </div>
              </div>
              <div class="block_16 flex-col">
                <div class="text-wrapper_29">
                  <span class="text_91">
                    我评论的&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span class="text_92">&nbsp;63</span>
                </div>
              </div>
              <div class="text-wrapper_30 flex-col"><span class="text_93">去发布供求信息</span></div>
            </div>
          </div>
        -->
          <div v-if="list.length > 0" class="group_81 flex-col justify-between">
            <div v-for="(item, index) in list" :key="index">
              <supply-card :form-data="item" />
            </div>
          </div>
          <div v-else class="group_81 flex-col justify-between">
            <div style="height:200px;text-align: center;font-size: medium;color:darkgray;margin-top:100px;"> 暂无数据 </div>
          </div>
          <bottom-detector :loadingMore="loading" :noMore="list.length >= total" @arriveBottom="loadData"
            :distance="400" />
        </div>
        <div class="group_90 flex-row">
          <div class="text-group_36 flex-col justify-between">
            <span class="text_114">供求信息大厅</span>
            <span class="text_115">全方位覆盖工业行业信息</span>
          </div>
        </div>
      </div>
      <fotter />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
import supplyCard from './supplyCard.vue'
import bottomDetector from '@/views/components/bottomDetector/index.vue'
import api from './api.js'
export default {
  components: {
    headerCom,
    fotter,
    supplyCard,
    bottomDetector
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      query: {
        // 三取一
        category: "最新",
        keywords: "",
        total: 0,
        size: 10,
        pn: 1,
      },
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(flg) {
      this.loading = true
      api.list(this.query).then((res) => {
        if (res.code === 200) {
          if (flg) {
            this.list = res.data.list
            this.total = res.data.total
            this.query.pn = 1
          } else {
            this.list = [...this.list, ...res.data.list]
            this.total = res.data.total
            this.query.pn++;
          }
          this.loading = false

        }
      })
    }
  },

};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />