<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="section_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云签约</span>
        <span class="text_4">高效、安全、便捷的在线签约平台</span>
   
      </div>
      <div id="content" class="group_13 flex-col">
        <div class="text-group_4 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;Signing</span>
          <span class="text_7">云签约</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            云签约板块是一种基于互联网技术的在线签约平台。
            <br />
          </span>
          <span class="paragraph_2">
            可以提供高效、安全、便捷的签约服务。实现跨地域、跨时空、跨边界的在线签署功能。帮助企业提高工作效率、降低成本。
            <br />
            云签约板块可以对包括内外部员工、供应商、客户、合作伙伴等多方进行签约，签订各种合同、协议、声明、授权书等多种业务文书。通过云签约模块，企业可以将签约流程电子化、规范化，从而降低成本、提高效率。
          </span>
        </div>
        <div class="box_5 flex-col">
          <div class="group_14 flex-row">
            <div class="text-group_5 flex-col justify-between">
              <span class="text_8">Main&nbsp;Services</span>
              <span class="text_9">主要服务</span>
            </div>
          </div>
          <div class="group_15 flex-row">
            <img class="image_6" referrerpolicy="no-referrer"
              src="./assets/img/pslo10q0w2evjilmfri9jsfy0ewihz2gk7edd9c37-a083-4043-8d49-bb1c3cda953c.png" />
            <div class="text-wrapper_6 flex-col justify-between">
              <span class="text_10">快速签署</span>
              <span class="text_11">约流程简单、便捷，可以快速完成签约。</span>
            </div>
            <img class="image_7" referrerpolicy="no-referrer"
              src="./assets/img/psayqhwxae8nudmjxt2y5fwitfgh0zlj13422958-54ff-40d6-91fb-87359ce665b9.png" />
            <div class="text-wrapper_7 flex-col justify-between">
              <span class="text_12">安全保障</span>
              <span class="text_13">
                提供多重安全验证机制，确保签约的安全性和可靠性。同时有专业的法务团队在线进行法务规范，确保合约的合法性与规范性。
              </span>
            </div>
          </div>
          <div class="group_16 flex-row">
            <img class="image_8" referrerpolicy="no-referrer"
              src="./assets/img/psn617lapfib1b0js5js5nlqsi7rg27b2rb7694d91-29b8-4929-91be-35f784036aa8.png" />
            <div class="text-wrapper_8 flex-col justify-between">
              <span class="text_14">文件管理</span>
              <span class="text_15">
                管理签约文件的电子文档，同时具备版本控制和存储备份功能，确保文件的完整性和安全性。
              </span>
            </div>
            <img class="image_9" referrerpolicy="no-referrer"
              src="./assets/img/ps1nm2fba6ol82zonfcu4g41pkq8am6g4d52922ab9-5270-4f20-9bbe-ab8acf18dc36.png" />
            <div class="text-wrapper_9 flex-col justify-between">
              <span class="text_16">便利性</span>
              <span class="text_17">支持多种终端和设备，可以随时随地进行签约操作。</span>
            </div>
          </div>
        </div>
        <div class="text-group_6 flex-col justify-between">
          <span class="text_18">application&nbsp;area</span>
          <span class="text_19">应用领域</span>
        </div>
        <div class="list_1 flex-row">
          <div :class="'text-wrapper_10' + index + ' flex-col'" v-for="(item, index) in loopData0" :key="index">
            <!-- <span class="text_20" :style="{ color: item.lanhufontColor0 }" v-html="item.lanhutext0"></span>/ -->
            <!-- <span class="text_21" v-html="item.lanhutext1"></span> -->
          </div>
        </div>

      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(./img/t1.png) 100% no-repeat',
          lanhutext0: '项目合同',
          lanhufontColor0: 'rgba(34,115,235,1)',
          lanhutext1: '签署项目合同，帮助企业快捷地完成各种项目合同的签署。'
        },

        {
          lanhuBg0:
            'url(./img/t2.png) 100% no-repeat',
          lanhutext0: '支付结算',
          lanhufontColor0: 'rgba(44,182,244,1)',
          lanhutext1: '签署支付结算协议，确保支付合同的安全性和有效性。'
        },

        {
          lanhuBg0:
            'url(./img/t3.png) 100% no-repeat',
          lanhutext0: '权益授权',
          lanhufontColor0: 'rgba(34,115,235,1)',
          lanhutext1: '签署权益授权协议，确保双方权益的有效保护。'
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />