<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云金融</span>
        <span class="text_4">利用供应链金融和税务管理为企业提供保障</span>
  
      </div>
      <div id="content" class="box_3 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;finance</span>
          <span class="text_7">云金融</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            云金融服务是与与大连信用中心和工行等多家银行联合成立的服务板块。
            <br />
          </span>
          <span class="text_8">
            主要是为了通过金融科技手段为企业提供金融服务，实现产融深度融合，促进实体经济的发展。
          </span>
        </div>
        <div class="box_4 flex-col">
          <div class="text-group_2 flex-col justify-between">
            <span class="text_9">Main&nbsp;Services</span>
            <span class="text_10">主要服务</span>
          </div>
          <div class="section_2 flex-row">
            <div class="image-text_1 flex-row justify-between">
              <img
                class="image_2"
                referrerpolicy="no-referrer"
                src="./assets/img/ps3l6cvl45g6nex9wco868a2uhk9gua43ve38d924a-740a-4223-baac-ceced40c6750.png"
              />
              <div class="text-group_3 flex-col justify-between">
                <span class="text_11">融资服务</span>
                <span class="text_12">
                  平台联动多家银行,&nbsp;向企业提供包括信贷、融资租赁、保理等多种融资服务,&nbsp;资金从银行直接流向企业,&nbsp;节约了企业与金融机构之间的信息获取成本,&nbsp;缩短了融资周期,&nbsp;提高了融资效率。
                </span>
              </div>
            </div>
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/ps9r5kbqaaliwpxin75q5zlrjxk76p1b2d0369f3-5f77-4578-b688-9af5c958d34e.png"
            />
            <div class="text-wrapper_2 flex-col justify-between">
              <span class="text_13">信用服务</span>
              <span class="text_14">
                平台利用大连信用中心的信用信息数据库,&nbsp;为企业提供包括信用评估、企业信用查询等信用服务,&nbsp;全面提升企业的信用等级,&nbsp;为获得更多的信贷和融资机会提供了良好的保障。
              </span>
            </div>
          </div>
          <div class="section_3 flex-row justify-between">
            <div class="image-text_2 flex-row justify-between">
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./assets/img/ps6w9nd1hpba9egtdfzaj5icfxeczxerhxc4750a1f-9f82-42a7-8286-5dd55706cb91.png"
              />
              <div class="text-group_4 flex-col justify-between">
                <span class="text_15">投融资服务</span>
                <span class="text_16">
                  平台通过与多家风险投资机构合作,&nbsp;为优秀的创业团队和项目提供投融资服务和孵化平台,&nbsp;支持产业链上下游企业进行投融资合作。
                </span>
              </div>
            </div>
            <div class="image-text_3 flex-row justify-between">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/psvee4iiey3hdw14arbqvjoncddtjjzawc894c734-a58f-4a87-a9de-ebd1549bc41d.png"
              />
              <div class="text-group_5 flex-col justify-between">
                <span class="text_17">资金管理服务</span>
                <span class="text_18">
                  平台联合工行等多家银行,&nbsp;为企业提供资金托管、账户管理等一系列资金管理服务,&nbsp;为企业提供更加便捷和高效的资金运营管理。
                </span>
              </div>
            </div>
          </div>
          <div class="text-wrapper_3 flex-col">
            <span class="text_19">
              云金融板块的成立旨在有效解决企业融资难、融资贵的问题，&nbsp;提高企业的金融服务水平,，推动产业升级和实体经济发展。
            </span>
            <span class="text_20">同时,&nbsp;也有利于银行等传统金融机构转型升级,实现自身可持续发展。</span>
          </div>
        </div>

        />
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />