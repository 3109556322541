<template>
  <div style="position: relative">
    <span class="text_3">云工厂</span>
    <span class="text_4">
      &nbsp;设备运营、产品制造、实时在线跟踪管理，搭平台、接应用、建生态，实现企业产品全生命周期管理
    </span>
    <img
      class="label_1"
      referrerpolicy="no-referrer"
      src="./assets/img/ps0egnsit9x5fd7nu27gv73z7alhkm7m1z8703edf198-7e53-4b4d-88e2-eba31adb05da.png"
    />
    <div class="box_3 flex-row">
      <div class="box_4 flex-col"></div>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/psj2vlelmm76furyjzl5qwfbw94hbjle6r9c570c05-e1ec-41af-b64c-4bd003fc1eae.png"
      />
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/ps2mul4wfhoyl0xcmxx79i58ws06fti2cab70e405f7-eb8d-4b95-8132-4ab633834046.png"
      />
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/ps6kpc9dn9d29riw9zsbhoik3epl2hpzyme248e5564-a6db-4882-b187-7a4205f53f2b.png"
      />
      <div class="box_5 flex-col"></div>
    </div>
    <div class="box_6 flex-col" :style="top">
      <img
        class="label_2"
        referrerpolicy="no-referrer"
        src="./assets/img/psv1c95fci598ej7v4m36yl7inqeaqarakbe07df823-d5e9-4ba9-8dd6-d9ec6dfc1a5a.png"
      />
      <span class="text_5">云官网</span>
      <div class="box_7 flex-col"></div>
      <span class="text_6">智能动态官网展示企业核心优势</span>
      <div class="box_8 flex-row justify-between">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="./assets/img/ps149e2gvtt89dwwxtsrnkmtjkldl8rxn14a010911-33b5-4d88-969d-71bb893897e8.png"
          />
        </div>
        <span class="text_7">立即探索</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    top: {
      type: String,
      // default: "top:225px",
      default: "top:4rem",
    },
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />