<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <!-- <div class="box_1 flex-row">
      <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/ps6vxnbjaeawzeu270xun0ir8o2264bw1gb0c8fce6-ff9d-42cd-bbc0-353802b94ec2.png"
        />
        <span class="text_1">首页&nbsp;云工厂&nbsp;云商城&nbsp;云服务&nbsp;关于我们</span>
        <span class="text_2">登录&nbsp;&nbsp;|&nbsp;&nbsp;注册</span>
      </div> 
           
      -->
      <headerCom fontColor="color:white" />
      <div class="box_2 flex-col">
        <!-- <div class="group_1 flex-col"></div> -->
      </div>
      <part />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import part from "./part.vue";
export default {
  components: {
    headerCom,
    part,
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />