import request from '@/utils/request'
export default {
    initPageData() {
        return request({
            url: 'host/hostWebSite/initPageData',
            method: 'get'
        })
    },
    list(data) {
        return request({
            url: 'host/hostWebSite/list',
            method: 'post',
            data
        })
    },
    getWebsiteQcCode(id){
        return request({
            url: `/host/hostWebSite/getWebsiteQcCode/${id}`,
            method: 'get',
        })
    },
    getPageContentById(data) {
        return request({
            url: '/website/getPageContentById',
            method: 'post',
            data
        })
    },
    getPageContentList(data) {
        return request({
            url: '/website/getPageContentList',
            method: 'post',
            data
        })
    }
}