<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="box_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云展厅</span>
        <span class="text_4">全息技术沉浸式展示产品，形成云目录实现产品目录营销</span>
        <div class="group_4 flex-row justify-between" @click="$scrollToTop('content')" style="cursor: pointer;">
          <div class="image-wrapper_1 flex-col">
            <img class="thumbnail_1" referrerpolicy="no-referrer"
              src="./assets/img/ps3utmwmba9yfhf01bf449vqwz3daxl5gba217957d4-7ec9-40f8-858b-7d5cabf6ee4f.png" />
          </div>
          <span class="text_5">立即探索</span>
        </div>
      </div>
      <!--
      <div class="box_2 flex-col">
        <div class="block_1 flex-col">
          <div class="box_3 flex-row">
            <span class="text_6">最新</span>
            <span class="text_7">推荐</span>
            <span class="text_8">同城</span>
            <div class="block_2 flex-col">
              <span class="text_9">搜索关键字</span>
              <div class="image-wrapper_2 flex-col">
                <img
                  class="label_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pssj9bn6u73r6egtx51ba9a20jtoswlz255b2287ae-352d-47b9-adc4-af4ae2d278cb.png"
                />
              </div>
            </div>
          </div>
          <div class="box_4 flex-row"><div class="group_5 flex-col"></div></div>
        </div>
        <div class="grid_1 flex-row">
          <div class="list-items_1 flex-col justify-end" v-for="(item, index) in loopData0" :key="index">
            <img class="image_2" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
            <div class="text-wrapper_1 flex-col"><span class="text_10" v-html="item.lanhutext0"></span></div>
          </div>
        </div>
        <div class="block_3 flex-row">
          <div class="text-group_1 flex-col justify-between">
            <span class="text_11">云展厅</span>
            <span class="text_12">全息沉浸展示</span>
          </div>
        </div>
      </div>
           -->
      <div id="content" class="box_5 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_13">Cloud&nbsp;Exhibition&nbsp;Hall</span>
          <span class="text_14">云展厅</span>
        </div>
        <span class="paragraph_1">
          云展厅是一款创新的VR在线展览展厅，通过利用3D数字化联动能力，实现展会沉浸式体验，还原展厅内场景真实空间，为传统展会向数字化转型赋能。与传统展会相比，云展厅具有更多的优势和特点，让参展更加便捷、高效，同时也让参观更加灵活、自由。
          <br />
        </span>
        <div class="group_6 flex-col">
          <div class="block_4 flex-row">
            <div class="text-group_3 flex-col justify-between">
              <span class="text_15">service&nbsp;features</span>
              <span class="text_16">服务特点</span>
            </div>
          </div>
          <div class="block_5 flex-row justify-between">
            <div class="image-text_1 flex-row justify-between">
              <img class="image_4" referrerpolicy="no-referrer"
                src="./assets/img/psuy24d60zmdzj42pv7acpeabe2fmml1212320e17-8fd6-4872-a92e-70380d8b5a70.png" />
              <div class="text-group_4 flex-col justify-between">
                <span class="text_17">模拟参加展会</span>
                <span class="text_18">
                  用户可以随时随地模拟参加展会，无需再为时间、地点的限制而烦恼。这种全天候、全年度的展览方式，可以极大地满足用户的需求。
                </span>
              </div>
            </div>
            <div class="image-text_2 flex-row justify-between">
              <img class="image_5" referrerpolicy="no-referrer"
                src="./assets/img/psgu4qgn8uert6r2jioz01bmvktmwqqc170becd31-a71e-4795-ad13-8ecc6a56f319.png" />
              <div class="text-group_5 flex-col justify-between">
                <span class="text_19">永不落幕的展览</span>
                <span class="text_20">
                  云展厅具有永不落幕的特点。展览将一直保持开放，无论您何时何地想参观都可以随时进入展厅。这种特点有效降低时间和空间成本，增加了展示周期，深化展会展览成果，创新智慧数字化会展服务，打造永不落幕的展会。
                </span>
              </div>
            </div>
          </div>
          <div class="block_6 flex-row justify-between">
            <div class="image-text_3 flex-row justify-between">
              <img class="image_6" referrerpolicy="no-referrer"
                src="./assets/img/psd91t8cirdk8es0owca45xwn5rfnk1v0rb61a486a-bd9e-4cb0-a77c-06a39d9803ce.png" />
              <div class="text-group_6 flex-col justify-between">
                <span class="text_21">降本增效的展览方式</span>
                <span class="text_22">
                  云展厅采用的展览方式可以降低展览组织方和展商的成本。相比传统模式，展览组织方不需要承担场馆租赁、物流等高额费用，而展商也可以通过云展厅走进更多的国际市场，将传统的线下展览变成全新的线上体验。
                </span>
              </div>
            </div>
            <div class="image-text_4 flex-row justify-between">
              <img class="image_7" referrerpolicy="no-referrer"
                src="./assets/img/psz0uyg1j2k7ou89gkw0ck6ka78sve1x9ebc75a542a-5f00-4cbd-89e2-bbcfb95eb78f.png" />
              <div class="text-group_7 flex-col justify-between">
                <span class="text_23">智能化的展会运营</span>
                <span class="text_24">
                  在云展厅中，展商可以通过数据分析了解参观者的偏好、热点产品等信息，从而为未来的展览做出更加精准的决策。这种智能化的展会运营方式，有助于降低成本、提高效率，同时也能够更好地满足用户需求。
                </span>
              </div>
            </div>
          </div>
        </div>
        <img class="image_8" referrerpolicy="no-referrer"
          src="./assets/img/ps8rih23qb1pgajc5p1julfrf1m0b5duejl93858062-35e8-45db-8e93-9e81db34397d.png" />
      </div>
    </div>
    <fotter :showTag="true" />
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psgm11kgj2wsgg0wy1w3k9skq6wz53933hm7390ed31-f404-4d0e-bd59-ddfecc5fea1f',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psxgx34v17vxecfn4mnoro1fq2m1ix3l1taf597f19-4c22-4506-b3c6-e94728114790',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psixkwyk4tub9kmm2gxje6foj2y1fbyl0r8ab1989c-3e78-462d-be80-2ccb187415b9',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psh2besarngbuoyk9tfzvlrnf10kbiv6rs5723ed6c-6598-408d-b279-afaf367049e8',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psslfmmzboi1occ1i1nkg2vgqud4n7iqg5f457685-e0f6-4f09-8abf-e89b1941944e',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psimnz3tdrpq92es01n7co9l6xatgt1k1lc7869a6f-400d-4d0d-b481-a5f50b299539',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/pszk3tygs1qfm8ot7twth65wan028hkh6s5be0f7db5-16db-42b6-9a4f-3c64eca49c48',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psrp7sdafxjff6bd9ivy623a2zjwcc45mi95fa0cb3-69eb-49cd-a6e0-1282f022f7a1',
          lanhutext0: '全息云展厅'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psk3d32yjr6add6toadtkdq9mlsfx0diw587c8e742-11f9-4a4b-95a5-2a306979322b',
          lanhutext0: '全息云展厅'
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />