<template>
  <!-- <div class="page flex-col">
    <div class="box_1 flex-col"> -->
  <div class="box_2 flex-row">
    <div class="yfw_btn_box_3 flex-col" @click="to('/lanhu_yunkeyan')">
      >
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/20230523134922.png"
      />
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/psdwi889oar8i1jt6z38cvn9lx2w0itqzi4431228f-63e8-4f38-9ba6-48d9d0026753.png"
      />
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/研发.png"
        style="margin: 0.8rem 0 0 3.707rem;"
      />
      <span class="text_1">云可研</span>
      <span class="text_4">项目可行性论证，规划项目完整布局</span>
      <img
        class="label_1"
        referrerpolicy="no-referrer"
        src="./assets/img/psh2y7ybh2r774yas984n38bcy3vplj88w5265d082-12b5-4406-babb-280c4f82d8b5.png"
      />
    </div>
    <div class="yfw_btn_box_4 flex-col" @click="to('/lanhu_yunsheji')">
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/psobroww5s0l1pusgdgqu826kccmmky3lb8d98d81a-ecfa-4e5b-9014-d33741508006.png"
      />
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/psdwi889oar8i1jt6z38cvn9lx2w0itqzi4431228f-63e8-4f38-9ba6-48d9d0026753.png"
      />
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/设计.png"
      />
      <span class="text_3">云设计</span>
      <span class="text_4"
        >产品&nbsp;工艺&nbsp;管理&nbsp;售后全方位运营链设计</span
      >
      <img
        class="label_2"
        referrerpolicy="no-referrer"
        src="./assets/img/psh2y7ybh2r774yas984n38bcy3vplj88w5265d082-12b5-4406-babb-280c4f82d8b5.png"
      />
    </div>
    <div class="yfw_btn_box_5 flex-col" @click="to('/lanhu_yunjinrong')">
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/pspfppeex65ckuay9m86tenc14fvjld20sldd8e3e1df-3ac8-411f-b9a6-c0f5c4e5d224.png"
      />
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/psdwi889oar8i1jt6z38cvn9lx2w0itqzi4431228f-63e8-4f38-9ba6-48d9d0026753.png"
      />

      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/金融.png"
      />
      <span class="text_5">云金融</span>
      <span class="text_4">
        利用供应链金融和税务管理为企业
        <br />
        提供保障
      </span>
      <img
        class="label_2"
        referrerpolicy="no-referrer"
        src="./assets/img/psh2y7ybh2r774yas984n38bcy3vplj88w5265d082-12b5-4406-babb-280c4f82d8b5.png"
      />
    </div>
    <div class="yfw_btn_box_6 flex-col" @click="to('/lanhu_yunqiatan')">
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/psvmog03jmmm4ppmxw0vdpeziltmspfiw86929c101-5af0-45e9-aee3-57292ac4f3d8.png"
      />
      <img
        style="position: absolute; width: 100%; height: 100%"
        src="./assets/img/psdwi889oar8i1jt6z38cvn9lx2w0itqzi4431228f-63e8-4f38-9ba6-48d9d0026753.png"
      />

      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/洽谈.png"
      />
      <span class="text_6">云洽谈</span>
      <span class="text_4">集结万名业内专家制定专项解决方案</span>
      <img
        class="label_2"
        referrerpolicy="no-referrer"
        src="./assets/img/psh2y7ybh2r774yas984n38bcy3vplj88w5265d082-12b5-4406-babb-280c4f82d8b5.png"
      />
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>
<script>
import router from "@/router";
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {
    to(url) {
      router.push(url);
    },
  },
};
</script>
<style scoped lang="css" src="./assets/partBtn.rem.css" />