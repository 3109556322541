import request from '@/utils/request'
export default {
    listCategory(query) {
        return request({
            headers: {
                'tenantId': query.tenantId
            },
            url: 'identity/product/category/list',
            method: 'get',
            params: query
        })
    },
    listProduct(query) {
        return request({
            headers: {
                'tenantId': query.tenantId
            },
            url: 'identity/product/list',
            method: 'get',
            params: query
        })
    },
    getTenantInfo(id) {
        return request({
            url: `identity/tenant/${id}`,
            method: 'get',
        })
    },
}