<template>
  <div style="position: relative">
    <span class="text_3">云工厂</span>
    <span class="text_4">
      &nbsp;设备运营、产品制造、实时在线跟踪管理，搭平台、接应用、建生态，实现企业产品全生命周期管理
    </span>

    <div>
      <a href="/lanhu_yungongchang">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/ps0egnsit9x5fd7nu27gv73z7alhkm7m1z8703edf198-7e53-4b4d-88e2-eba31adb05da.png"
        />
      </a>
    </div>
    <div class="mainDyn">
      <div id="pic1" class="pic1" data-startx="0" data-endx="0">
        <img
          src="./assets/loopImg/云合作.jpg"
          id="pic1Im"
          style="width: 100%; height: 100%"
        />
        <div style="position: absolute; top: 4rem; color: white; left: 2.5rem">
          云合作
        </div>
        <div
          style="
            position: absolute;
            top: 7rem;
            color: white;
            left: 2.5rem;
            font-size: 0.5rem;
          "
        >
          资源、服务、应用集成访问接口
        </div>
        <button
          style="
            position: absolute;
            top: 12rem;
            left: 2.5rem;
            background-color: #0a6df3;
            height: 1.094rem;
            width: 4.827rem;
            color: white;
            font-size: 0.426rem;
          "
          @click="to('/lanhu_yunhezuo')"
        >
          立即探索
        </button>
      </div>

      <div class="leftBtn" id="leftBtn" v-if="leftBtn">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/psj2vlelmm76furyjzl5qwfbw94hbjle6r9c570c05-e1ec-41af-b64c-4bd003fc1eae.png"
          @click="rightMove(true)"
        />
      </div>

      <div id="pic2" class="pic2" data-startx="0" data-endx="0">
        <img
          id="pic2Im"
          src="./assets/loopImg/云名片.jpg"
          style="width: 100%; height: 100%"
        />
        <div style="position: absolute; top: 4rem; color: white; left: 2.5rem">
          云名片
        </div>
        <div
          style="
            position: absolute;
            top: 7rem;
            color: white;
            left: 2.5rem;
            font-size: 0.5rem;
          "
        >
          让你的联系方式更便捷
        </div>
        <button
          style="
            position: absolute;
            top: 12rem;
            left: 2.5rem;
            background-color: #0a6df3;
            height: 1.094rem;
            width: 4.827rem;
            color: white;
            font-size: 0.426rem;
          "
          @click="to('/lanhu_yunmingpian')"
        >
          立即探索
        </button>
      </div>

      <div class="rightBtn" id="rightBtn" v-if="rightBtn">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/ps6kpc9dn9d29riw9zsbhoik3epl2hpzyme248e5564-a6db-4882-b187-7a4205f53f2b.png"
          @click="leftMove(true)"
        />
      </div>

      <div id="pic3" class="pic3" data-startx="0" data-endx="0">
        <img
          src="./assets/loopImg/云地图.png"
          id="pic3Im"
          style="width: 100%; height: 100%"
        />
        <div style="position: absolute; top: 4rem; color: white; left: 2.5rem">
          云地图
        </div>
        <div
          style="
            position: absolute;
            top: 7rem;
            color: white;
            left: 2.5rem;
            font-size: 0.5rem;
          "
        >
          在线展示工业企业位置信息
        </div>
        <button
          style="
            position: absolute;
            top: 12rem;
            left: 2.5rem;
            background-color: #0a6df3;
            height: 1.094rem;
            width: 4.827rem;
            color: white;
            font-size: 0.426rem;
          "
          @click="to('/lanhu_yunditu')"
        >
          立即探索
        </button>
      </div>

      <div id="pic4" class="pic4" data-startx="89" data-endx="0">
        <img
          src="./assets/loopImg/云官网.png"
          id="pic3Im"
          style="width: 100%; height: 100%"
        />
        <div style="position: absolute; top: 4rem; color: white; left: 2.5rem">
          云官网
        </div>
        <div
          style="
            position: absolute;
            top: 7rem;
            color: white;
            left: 2.5rem;
            font-size: 0.5rem;
          "
        >
          智能动态官网展示企业核心优势
        </div>
        <button
          style="
            position: absolute;
            top: 12rem;
            left: 2.5rem;
            background-color: #0a6df3;
            height: 1.094rem;
            width: 4.827rem;
            color: white;
            font-size: 0.426rem;
          "
          @click="to('/lanhu_yunguanwang')"
        >
          立即探索
        </button>
      </div>

      <div id="pic5" class="pic5" data-startx="130" data-endx="0">
        <img
          src="./assets/loopImg/云标识.png"
          id="pic3Im"
          style="width: 100%; height: 100%"
        />
        <div style="position: absolute; top: 4rem; color: white; left: 2.5rem">
          云标识
        </div>
        <div
          style="
            position: absolute;
            top: 7rem;
            color: white;
            left: 2.5rem;
            font-size: 0.5rem;
          "
        >
          国家标识 数字基石
        </div>
        <button
          style="
            position: absolute;
            top: 12rem;
            left: 2.5rem;
            background-color: #0a6df3;
            height: 1.094rem;
            width: 4.827rem;
            color: white;
            font-size: 0.426rem;
          "
          @click="to('/lanhu_yunbiaoshi')"
        >
          立即探索
        </button>
      </div>

      <div id="pic6" class="pic6" data-startx="171" data-endx="0">
        <img
          src="./assets/loopImg/创新联合体.jpg"
          id="pic3Im"
          style="width: 100%; height: 100%"
        />
        <div style="position: absolute; top: 4rem; color: white; left: 2.5rem">
          云创新联合体
        </div>
        <div
          style="
            position: absolute;
            top: 7rem;
            color: white;
            left: 2.5rem;
            font-size: 0.5rem;
          "
        >
          凝聚产业链力量 发挥成员优势
        </div>
        <button
          style="
            position: absolute;
            top: 12rem;
            left: 2.5rem;
            background-color: #0a6df3;
            height: 1.094rem;
            width: 4.827rem;
            color: white;
            font-size: 0.426rem;
          "
          @click="to('/lanhu_yunchuangxinlianheti')"
        >
          立即探索
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
export default {
  props: {
    top: {
      type: String,
      // default: "top:225px",
      default: "top:4rem",
    },
  },
  data() {
    return {
      leftBtn: true,
      rightBtn: true,

      // 移动方向
      // 左1,右2
      moveDic: 1,
    };
  },
  mounted() {
    // this.loopMove();
  },
  methods: {
    to(path) {
      router.push(path);
    },
    allMove(dic) {
      console.log("allMove");
      var allDiv = ["pic1", "pic2", "pic3", "pic4", "pic5", "pic6"];

      var item = null;
      for (var i = 0; i < allDiv.length; i++) {
        item = document.getElementById(allDiv[i]);
        if (item == null) {
          continue;
        }
        var an1 = "";
        if (item.dataset.endx == 0) {
          an1 = "translateX(" + 0 + "rem)";
        } else {
          an1 = "translateX(" + parseFloat(item.dataset.endx) + "rem)";
        }
        var an2 = "";
        var temp2 = parseFloat(item.dataset.endx) + parseFloat(dic);
        an2 = "translateX(" + temp2 + "rem)";
        item.animate([{ transform: an1 }, { transform: an2 }], {
          duration: 1000,
          fill: "forwards",
        });
        item.dataset.endx = parseFloat(item.dataset.endx) + parseFloat(dic);
      }
      this.reset();
    },
    reset() {
      this.leftBtn = true;
      this.rightBtn = true;
      var div6 = document.getElementById("pic6");
      var currentX = parseFloat(div6.dataset.endx);
      //  6号照片在正中心
      if (-164 >= currentX) {
        this.rightBtn = false;
      } else {
        this.leftBtn = true;
      }

      var div1 = document.getElementById("pic1");
      currentX = parseFloat(div1.dataset.endx);
      //  1号照片在正中心
      if (41 <= currentX) {
        this.leftBtn = false;
      } else {
        this.leftBtn = true;
      }
    },
    loopMove() {
      if (this.autoLoop) {
        if (this.moveDic == 1) {
          this.leftMove(false);
        } else {
          this.rightMove(false);
        }
      }
      setTimeout(() => {
        this.loopMove();
      }, 5000);
    },
    leftMove() {
      return this.allMove(-41);
    },

    rightMove() {
      return this.allMove(41);
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
<style scoped>
.mainDyn {
  width: 51.89rem;
  height: 21.62rem;
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;
}

.pic1 {
  display: inline-block;
  position: absolute;
  width: 37.03rem;
  height: 15.88rem;
  top: 1.08rem;
  left: -34rem;
}

.leftBtn {
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  cursor: pointer;
}

.pic2 {
  display: inline-block;
  position: absolute;
  width: 37.03rem;
  height: 15.88rem;
  top: 1.08rem;
  left: 7rem;
}

.rightBtn {
  display: inline-block;
  position: absolute;
  top: 1.08rem;
  left: 42.5rem;
  cursor: pointer;
}

.pic3 {
  display: inline-block;
  position: absolute;
  width: 37.03rem;
  height: 15.88rem;
  top: 1.08rem;
  left: 48rem;
}

.pic4 {
  display: inline-block;
  position: absolute;
  width: 37.03rem;
  height: 15.88rem;
  top: 1.08rem;
  left: 89rem;
}

.pic5 {
  display: inline-block;
  position: absolute;
  width: 37.03rem;
  height: 15.88rem;
  top: 1.08rem;
  left: 130rem;
}

.pic6 {
  display: inline-block;
  position: absolute;
  width: 37.03rem;
  height: 15.88rem;
  top: 1.08rem;
  left: 171rem;
}
</style>