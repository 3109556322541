<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="box_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云集采</span>
        <span class="text_4">设备、原材料采集中心</span>
   
      </div>
      <div id="content" class="box_2 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;Collection</span>
          <span class="text_7">云集采</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            精一云创工业互联网平台提供的集中采购中心
            <br />
          </span>
          <span class="text_8">
            云集采是精一云创工业互联网平台提供的集中采购中心，针对轴承企业打造的专属集中式采购平台，解决企业面临的，成本高、价格波动大、低效率、质量不稳定等痛点。依托国企背景，整合企业资源，打通上游供应链，由供应商定点发货，企业仓储“0”成本，帮助企业构建智能化商业生态圈。
          </span>
        </div>
        <div class="block_4 flex-col">
          <div class="box_3 flex-row">
            <div class="text-group_2 flex-col justify-between">
              <span class="text_9">service&nbsp;features</span>
              <span class="text_10">服务特点</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="block_5 flex-col">
              <div class="image-text_1 flex-col justify-between">
                <img class="image_2" referrerpolicy="no-referrer"
                  src="./assets/img/psrt2beocvyfgkhp0r5w062h9uwuw282qle9aa14bbd-7262-4fc6-bb03-2ff22c34f722.png" />
                <span class="text-group_3">汇集优秀供应商</span>
              </div>
              <span class="text_11">
                云集采背靠国企大平台，通过集采平台汇集全球优秀供应商，确保采购来源的可靠性。这种集中采购方式可以为企业提供更多的选择空间和利润保障。在云集采平台上，企业可以根据自身需求，按照“品牌”、“类别”、“规格”等多个维度筛选出优秀供应商，以最小的采购成本获得最大的采购收益。
              </span>
            </div>
            <div class="block_6 flex-col">
              <div class="image-text_2 flex-col justify-between">
                <img class="image_3" referrerpolicy="no-referrer"
                  src="./assets/img/psf3lam1e8o6fi8g3aru14f0ga36zkxq55e7d3a1-bf51-47b9-8e7c-18d21045851f.png" />
                <span class="text-group_4">实现采购招标的规范、高效</span>
              </div>
              <span class="paragraph_2">
                通过集采平台，云集采实现了采购招标的规范、高效管理。用户可以快速发布招标公告、供应商资格审查、供应商集中报价、供应商自动比选、采购合同生成、采购中标通知发布等全过程自动化管理，降低了人工成本和风险。
                <br />
                在云集采平台上，企业可以根据自身需求，对供应商进行筛选和推荐，以最小的时间完成采购流程，并获得最优质的产品和服务。
              </span>
            </div>
            <div class="block_7 flex-col">
              <div class="image-text_3 flex-col justify-between">
                <img class="image_4" referrerpolicy="no-referrer"
                  src="./assets/img/psqqh1dtldebbntd4dkma4su8y1i93r00q1613e0fc-c811-4045-be1b-1f19a781bab6.png" />
                <span class="text-group_5">实现集采大数据分析</span>
              </div>
              <span class="text_12">
                通过集采平台，云集采实现了对供应商各项品牌材料的价格波动分析，并可以实现各工程项目的采购招标价格、供货质量、效率、信用等评价。这样的大数据分析可以为企业提供更好的决策支持，降低采购成本和风险，企业可以充分了解市场变化趋势，及时做出决策调整并实现精准采购。同时，在云集采平台上还可以获取最新行业资讯和供应链信息，大大降低了企业采购的不确定性。
              </span>
            </div>
          </div>
        </div>
       
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />