<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云可研</span>
        <span class="text_4">利用供应链金融和税务管理为企业提供保障</span>
   
      </div>
      <div id="content" class="block_6 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;feasibility&nbsp;study</span>
          <span class="text_7">云可研</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            为企业提供智能化可研服务的解决方案。
            <br />
          </span>
          <span class="text_8">
            该板块包括市场调研、方案定制、技术评估、投资评估等功能，<br>旨在为企业提供基于工业互联网的可行性研究服务，为企业项目决策提供科学依据。
          </span>
        </div>
        <div class="block_7 flex-row">
          <div class="box_2 flex-col">
            <img class="image_2" referrerpolicy="no-referrer"
              src="./assets/img/psn8d6tsynwus61sgo6ewcywrw9wxmpe0ff6a531-07b2-459e-88d1-e6980a3acd02.png" />
            <span class="text_9">市场调研</span>
            <span class="text_10">
              精一云创工业互联网平台提供全面的市场研究数据，对市场需求、竞争情况、行业发展趋势进行分析，为企业项目决策提供实时可靠的参考资料。
            </span>
          </div>
          <div class="box_3 flex-col">
            <img class="image_3" referrerpolicy="no-referrer"
              src="./assets/img/pso8p31f98sxm87pzkepngoe0qeu3lxhagqr43ad2fa4-53e0-43a7-b934-61c629051cee.png" />
            <span class="text_11">方案定制</span>
            <span class="text_12">
              精一云创工业互联网平台根据企业需求和市场研究结果，对项目进行方案定制，提供方案设计、技术咨询、投资评估等服务。
            </span>
          </div>
          <div class="box_4 flex-col">
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/psow03s4vi1bxcp1tb598cb0mhex1dyjkla069467d4-68c3-46ba-b93e-21732fe614c6.png" />
            <span class="text_13">技术评估</span>
            <span class="text_14">
              精一云创工业互联网平台提供专业的技术评估服务，通过对技术方案的分析和评价，帮助企业了解、优化和提升技术水平，为企业的项目决策提供科学依据。
            </span>
          </div>
          <div class="box_5 flex-col">
            <img class="image_5" referrerpolicy="no-referrer"
              src="./assets/img/psn68kvetr8vmt7qgvg6j3jbh0xz65ta90a9011258c-ace0-4727-8955-7e7c94018930.png" />
            <span class="text_15">投资评估</span>
            <span class="text_16">
              精一云创工业互联网平台提供全面的投资评估服务，包括项目资金规划、投资效益分析等，帮助企业实现可研报告、招商引资和资金管理等投资决策全流程支持。
            </span>
          </div>
        </div>

        <span class="paragraph_2">
          精一云创工业互联网平台云可研板块是一个集市场调研、方案定制、技术评估、投资评估于一体的智能化可研解决方案。
          <br />
          为企业提供科学、准确、可靠的项目决策支持，帮助企业实现可持续发展和更高的商业价值。
        </span>
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psf92us1le56dg3bpn2v5kytkgesiwfgmpgaaff8b56-0952-40a4-aa1d-eda683b0bc21'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psxlyimepzr1e25q7d0jx6oiy9c9npjds8e112d90-2bf6-431a-b464-a70cd5430a34'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psnm21i66xo5olt5ze73emy76ucdu8rkiv3dd7b0e3f-1a91-4397-a5b2-c32572172010'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/pshrn3e0n9lw0jwrfe6b8vn8ujklm1ec7al2dadfca3-7552-4abe-ab17-96926ef13421'
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />