import request from '@/utils/request'
export default {
  // 以下是：首页 api
  initPageData() {
    return request({
      url: 'host/hostCard/initPageData',
      method: 'get'
    })
  },
  list(data) {
    return request({
      url: 'host/hostCard/list',
      method: 'post',
      data
    })
  },
  info(data) {
    return request({
      url: 'host/hostCard/info',
      method: 'post',
      data
    })
  }
}