<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="box_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云服务</span>
        <span class="paragraph_1">
          集结万名业内专家提供云咨询服务，制定技术、生产等环节专项解决方案，
          <br />
          建立区域维修中心、对企业做好精准服务
        </span>

      </div>
      <div class="box_4 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_4">Main&nbsp;Services</span>
          <span class="text_5">主要服务</span>
        </div>
        <span class="text_6">工业互联网平台云服务模块可以为工业企业提供高效、稳定、安全的工业互联网服务。</span>
        <div class="section_3 flex-row justify-between">
          <div class="group_1 flex-col">
            <div class="block_1 flex-col"></div>
            <span class="text_7">设备运行状态监测</span>
            <span class="text_8">
              通过物联网技术和传感器设备，实时监测设备的运行状态，及时发现设备故障，并向售后服务人员发送通知。
            </span>
            <span class="text_9">Equipment&nbsp;operation&nbsp;status&nbsp;monitoring</span>
            <span class="text_10">01</span>
          </div>
          <div class="group_2 flex-col">
            <div class="group_3 flex-col"></div>
            <span class="text_11">设备数据采集服务</span>
            <span class="text_12">
              平台可以采集和上传设备的实时数据和运行状态，这些数据是实现设备智能监控及预测性维护的基础。
            </span>
            <span class="text_13">Device&nbsp;Data&nbsp;Collection&nbsp;Service</span>
            <span class="text_14">02</span>
          </div>
        </div>
        <div class="section_4 flex-row justify-between">
          <div class="group_4 flex-col">
            <div class="section_5 flex-col"></div>
            <span class="text_15">数据存储服务</span>
            <span class="text_16">
              平台可以提供安全稳定的云端存储服务，永久性存储设备的历史数据。用户可以根据需求选择不同的存储方案，包括按时间段存储、按设备类型存储等。
            </span>
            <span class="text_17">Data&nbsp;Storage&nbsp;Services</span>
            <span class="text_18">03</span>
          </div>
          <div class="group_5 flex-col">
            <div class="section_6 flex-col"></div>
            <span class="text_19">数据分析服务</span>
            <div class="text-group_3 flex-col justify-between">
              <span class="text_20">
                平台可以通过对设备数据的分析，预判未来可能出现的故障，并提供相应的预警和维护方案。同时，也可以借助数据挖掘技术对设备产生的数据进行实时处理和分析，以创建更高效的生产环境和运营流程。
              </span>
              <span class="text_21">Data&nbsp;Analysis&nbsp;Services</span>
            </div>
            <span class="text_22">04</span>
          </div>
        </div>
        <div class="section_7 flex-row justify-between">
          <div class="group_6 flex-col">
            <div class="group_7 flex-col"></div>
            <span class="text_23">远程服务支持</span>
            <span class="text_24">
              基于云服务技术，售后服务人员可以通过在平台上建立的远程服务连接，实现对设备的远程监管、故障分析和处理等操作。
            </span>
            <span class="text_25">Remote&nbsp;service&nbsp;support</span>
            <span class="text_26">05</span>
          </div>
          <div class="group_8 flex-col">
            <div class="box_5 flex-col"></div>
            <span class="text_27">售后服务流程管控</span>
            <span class="text_28">
              平台可以对售后服务流程进行全面管控，包括客户服务请求、服务任务派发、服务人员调度、服务现场管理等，确保售后服务流程的快速和高效运转。
            </span>
            <span class="text_29">After&nbsp;sales&nbsp;service&nbsp;process&nbsp;control</span>
            <span class="text_30">06</span>
          </div>
        </div>
        <div class="section_8 flex-row justify-between">
          <div class="block_2 flex-col">
            <div class="box_6 flex-col"></div>
            <span class="text_31">知识库管理和分享</span>
            <span class="text_32">
              平台通过搭建知识库，收集、整理和分享针对设备售后服务的技术资料、维修手册、应急处理流程等，帮助售后服务人员快速解决故障问题。
            </span>
            <span class="text_33">Knowledge&nbsp;base&nbsp;management&nbsp;and&nbsp;sharing</span>
            <span class="text_34">07</span>
          </div>
          <div class="block_3 flex-col">
            <div class="section_9 flex-col"></div>
            <span class="text_35">维护管理服务</span>
            <span class="text_36">
              平台可针对设备监控数据和分析结果，提供定制化的设备维护管理服务，并为用户提供基于维修服务的云平台，实现设备维修的无纸化管理。该系统可自动分配维护任务给指定的维护人员，也可以自动处理客户请求的维修服务。
            </span>
            <span class="text_37">Maintenance&nbsp;and&nbsp;management&nbsp;services</span>
            <span class="text_38">08</span>
          </div>
        </div>
        <div class="section_10 flex-row justify-between">
          <div class="box_7 flex-col">
            <div class="group_9 flex-col"></div>
            <span class="text_39">应用开发服务</span>
            <div class="text-group_4 flex-col justify-between">
              <span class="text_40">
                平台可以对多种下层设备进行无缝连接，将它们集成到云端，支持第三方应用或系统的开发和应用集成，以满足不同企业的需求。同时，也可支持应用开发者快速开发基于云的应用，简化应用程序的实现和管理。
              </span>
              <span class="text_41">Application&nbsp;development&nbsp;services</span>
            </div>
            <span class="text_42">09</span>
          </div>
          <div class="box_8 flex-col">
            <div class="block_4 flex-col"></div>
            <span class="text_43">安全保护服务</span>
            <span class="text_44">
              平台可以提供强大的安全保护策略，包括密钥管理、数据加密、网络安全等多重安全措施。同时，提供可靠的备份和灾难恢复计划，确保数据安全。
            </span>
            <span class="text_45">Security&nbsp;protection&nbsp;services</span>
            <span class="text_46">10</span>
          </div>
        </div>
        <div id="content" class="section_12 flex-col">
          <div class="text-group_6 flex-col justify-between">
            <span class="text_50">Cloud&nbsp;Services</span>
            <span class="text_51">云服务</span>
          </div>
          <span class="text_52">
            工业互联网平台云服务模块是一个集成了多个云服务功能的平台，可以提供综合性的解决方案来解决工业企业的各种问题。
          </span>
          <div class="group_10 flex-row">
            <partBtn />
          </div>
        </div>
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
import partBtn from "./partBtn.vue";
export default {
  components: {
    headerCom,
    fotter,
    partBtn,
  },
  data() {
    return {
      loopData0: [
        {
          lanhutext0: "云设计",
          lanhutext1: "产品&nbsp;工艺&nbsp;管理&nbsp;售后全方位运营链设计",
        },
        {
          lanhutext0: "云洽谈",
          lanhutext1: "集结万名业内专家制定专项解决方案",
        },
      ],
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />