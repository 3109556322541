<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云洽谈</span>
        <span class="text_4">集结万名业内专家制定专项解决方案</span>
  
      </div>
      <div id="content" class="block_2 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;Negotiation</span>
          <span class="text_7">云洽谈</span>
        </div>
        <div class="text-wrapper_1">
          <span class="text_8">云洽谈板块是一个</span>
          <span class="text_9">在线会议平台，用于企业的项目协作</span>
          <span class="paragraph_1">
            。
            <br />
          </span>
          <span class="text_10">
            它基于云计算和网络技术，可以让不同地区的企业通过互联网进行远程会议、视频会议和在线协作，从而大大提高了项目的协作效率和成果质量。
          </span>
        </div>
        <div class="box_2 flex-col">
          <div class="box_3 flex-row">
            <div class="text-group_2 flex-col justify-between">
              <span class="text_11">Main&nbsp;Services</span>
              <span class="text_12">主要服务</span>
            </div>
          </div>
          <div class="text-wrapper_2 flex-row"><span class="text_13">云洽谈板块主要包括以下功能：</span></div>
          <div class="box_4 flex-row">
            <img class="image_2" referrerpolicy="no-referrer"
              src="./assets/img/psfu1x6qo5t0r53dtzf97kqm79g99w2domj4b1a8749-1615-4f83-b5b1-8b37dd671809.png" />
            <div class="text-wrapper_3 flex-col justify-between">
              <span class="text_14">项目信息发布和搜索</span>
              <span class="text_15">
                企业可以在平台上发布自己的项目信息，包括项目类型、地区、需求等，并可以根据自己的需求搜索其他企业发布的相关信息，从而找到合适的项目合作伙伴。
              </span>
            </div>
            <img class="image_3" referrerpolicy="no-referrer"
              src="./assets/img/pszryrlg0vihgq9vrt3700fc1xvcde3c06d0402595-d4a6-49bc-a027-2ce5a439ecb7.png" />
            <div class="text-wrapper_4 flex-col justify-between">
              <span class="text_16">在线洽谈和协作</span>
              <span class="text_17">
                企业可以在平台上进行实时的视频会议、在线交流和协作，从而节省了时间和成本，同时提高了沟通效率和项目协作的质量。
              </span>
            </div>
          </div>
          <div class="box_5 flex-row">
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/psyb644p8jq69takap83ckzfg98vkx0uu1td808eaca-2d28-4d02-93e5-e8572e1589eb.png" />
            <div class="text-wrapper_5 flex-col justify-between">
              <span class="text_18">项目文件管理</span>
              <span class="text_19">
                企业可以在平台上上传和共享项目相关的文件，包括技术规范、合同、图纸等，也可以在线编辑和修改文件，方便多方共同协作。
              </span>
            </div>
            <img class="image_5" referrerpolicy="no-referrer"
              src="./assets/img/ps50roocqc6rv0wrg5impvw3hkmzqafoc1bq1f3626af-5878-4cb3-aa8b-e1e43d9f9cd2.png" />
            <div class="text-wrapper_6 flex-col justify-between">
              <span class="text_20">安全和保密</span>
              <span class="text_21">
                平台采用了安全的加密和认证机制，保证项目信息和数据的安全和保密，避免了信息泄漏和知识产权侵犯等问题。
              </span>
            </div>
          </div>
        </div>
        <div class="text-group_3 flex-col justify-between">
          <span class="text_22">Our&nbsp;Advantages</span>
          <span class="text_23">我们的优势</span>
        </div>
        <div class="box_6 flex-row justify-between">
          <div class="group_4 flex-col justify-between">
            <div class="text-wrapper_7">
              <span class="text_24">云洽谈板块可以帮助企业寻找合适的项目合作伙伴</span>
              <span class="paragraph_2">
                <br />
                更快地完成项目，提高项目的质量和效率
              </span>
            </div>
            <span class="text_25">
              对于跨国或地区的合作项目，云洽谈板块还可以降低沟通成本和交通费用，节约时间和资源，让企业在全球市场上更具竞争力。
            </span>
          </div>
          <img class="image_6" referrerpolicy="no-referrer"
            src="./assets/img/pszcwy3idjxmjnnz5t4rjva56bq1qj7c1r6bf7ec09-25e1-4c4f-bf8e-e24df0d1a1e5.png" />
        </div>
      
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />