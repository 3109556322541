<template>
  <div class="box_1 flex-row">
    <div class="section_1 flex-col">
      <div class="section_2 flex-col">
        <div class="box_2 flex-row">
          <img class="label_1" referrerpolicy="no-referrer"
            src="./assets/img/psi1czfk34n549nqb61wk9t8rqr9ee574b196fed5a-e9db-4c84-902e-345673d20cff.png" />
          <span class="text_1">云服务</span>
        </div>
        <span class="text_2">
          集结万名业内专家提供云咨询服务，制定技术、生产等环节专项解决方案，建立区域维修中心，对企业做好精准服务
        </span>
        <div class="image-text_1 flex-row justify-between">
          <a href="/lanhu_yunfuwu">
            <img class="label_2" referrerpolicy="no-referrer"
              src="./assets/img/ps19oh6ychwco7nbsjlgz92665ist0ugujkf7eed1b8-7673-4fa9-959d-fd2b5b122626.png" /></a>
          <span class="text-group_1">
            <a href="/lanhu_yunfuwu" style="color: white">立即探索</a>
          </span>
        </div>
      </div>
      <div class="section_3 flex-col">
        <div class="hb5_title" id="hb5_title" style="position: relative; padding-top: 2rem; padding-bottom: 4rem">
          <div style="position: absolute">
            <span class="text_3">云可研</span>
            <span class="text_4">在线会议平台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业项目洽谈协作</span>
            <a href="/lanhu_yunkeyan">
              <img class="label_3" referrerpolicy="no-referrer"
                src="./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png" /></a>
          </div>
          <div style="position: absolute">
            <span class="text_3">云设计</span>
            <span class="text_4">产品&nbsp;工艺&nbsp;管理&nbsp;售后全方位运营链设计</span>
            <a href="/lanhu_yunsheji">
              <img class="label_3" referrerpolicy="no-referrer"
                src="./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png" /></a>
          </div>
          <div style="position: absolute">
            <span class="text_3">云金融</span>
            <span class="text_4">融资服务&nbsp;信用服务&nbsp;投融资服务&nbsp;资金管理服务</span>
            <a href="/lanhu_yunjinrong">
              <img class="label_3" referrerpolicy="no-referrer"
                src="./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png" /></a>
          </div>
          <div style="position: absolute">
            <span class="text_3">云洽谈</span>
            <span class="text_4">在线会议平台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业项目洽谈协作</span>
            <a href="/lanhu_yunqiatan">
              <img class="label_3" referrerpolicy="no-referrer"
                src="./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png" /></a>
          </div>
        </div>

        <div class="image-wrapper_1 flex-row">
          <img class="image_1" referrerpolicy="no-referrer"
            src="./assets/img/psr6j0pal51wqtdlwsqis1gu4bbjj7zpuh4608b848-a504-4627-b0c6-b98987571591.png"
            @click="leftClick" />

          <div class="image_2" id="hb5_img">
            <img referrerpolicy="no-referrer" src="./assets/loopImg/云可研.png" />
            <img referrerpolicy="no-referrer" src="./assets/loopImg/云设计.png" />

            <img referrerpolicy="no-referrer" src="./assets/loopImg/云金融.png" />
            <img referrerpolicy="no-referrer" src="./assets/loopImg/云洽谈.png" />
          </div>
          <img class="image_3" referrerpolicy="no-referrer"
            src="./assets/img/pscigvvxbxemoj2jsndwd1gp05s8gtdyugn506a74d79-a147-491b-93c1-454973fec04c.png"
            @click="rightClick" />
        </div>
        <div class="box_3 flex-row">
          <div class="image-text_2 flex-col justify-between" @click="setValueByIndex(0, 1, 0)"
            @mouseenter="setValueByIndex(0, 1, 0)">
            <div class="label_4" id="hb5_lb4" style="position: relative">
              <img referrerpolicy="no-referrer"
                src="./assets/img/psqxgp7bxfesbaz1po6r8m4nrz1uaijxh9ja169d2f3-375b-4e5e-8ee0-3fb477975724.png"
                style="position: absolute" />
              <img referrerpolicy="no-referrer" src="./assets/img/yky_hover.png" style="position: absolute" />
            </div>
            <span class="text-group_2">云可研</span>
          </div>
          <div class="image-text_3 flex-col justify-between" @click="setValueByIndex(1, 1, 0)"
            @mouseenter="setValueByIndex(1, 1, 0)">
            <div class="label_5" id="hb5_lb5" style="position: relative">
              <img referrerpolicy="no-referrer" src="./assets/img/ysj.png" style="position: absolute" />
              <img referrerpolicy="no-referrer" src="./assets/img/ysj_hover.png" style="position: absolute" />
            </div>
            <span class="text-group_3">云设计</span>
          </div>
          <div class="image-text_4 flex-col justify-between" @click="setValueByIndex(2, 1, 0)"
            @mouseenter="setValueByIndex(2, 1, 0)">
            <div class="label_6" id="hb5_lb6" style="position: relative">
              <img referrerpolicy="no-referrer"
                src="./assets/img/psaxuz6yjiamaqpxvj2wlk684ne0qm7pnao370c1404-9424-4735-a2f9-6e6765d361ae.png"
                style="position: absolute" />
              <img referrerpolicy="no-referrer" src="./assets/img/yjr_hover.png" style="position: absolute" />
            </div>
            <span class="text-group_4">云金融</span>
          </div>
          <div class="image-text_5 flex-col justify-between" @click="setValueByIndex(3, 1, 0)"
            @mouseenter="setValueByIndex(3, 1, 0)">
            <div class="label_7" id="hb5_lb7" style="position: relative">
              <img referrerpolicy="no-referrer"
                src="./assets/img/psphn5b2mgv98fgagqxzsazlzid1yt2krgc3d4347de-4e5e-4fce-a2ac-fc9ccdec6187.png"
                style="position: absolute" />
              <img referrerpolicy="no-referrer" src="./assets/img/yqt_hover.png" style="position: absolute" />
            </div>
            <span class="text-group_5">云洽谈</span>
          </div>
        </div>
        <!-- <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/pssndnbq428bopv2s277spfp7uib5h99d39827b2-8ac2-4b88-aa86-7c263d818791.png"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
<style scoped>
.image_2 {
  position: relative;
}

.image_2 img {
  position: absolute;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  width: 100%;
  height: 100%;
}

.image_2 img:nth-child(1) {
  opacity: 1;
}

.image_2 img:nth-child(2) {
  opacity: 0;
}

.image_2 img:nth-child(3) {
  opacity: 0;
}

.image_2 img:nth-child(4) {
  opacity: 0;
}
</style>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setValueByIndex(0, 1, 0);

      // this.btnSetVauleByIndex(0);
      // this.btnSetVauleByIndex(1);
      // this.btnSetVauleByIndex(2);
      // this.btnSetVauleByIndex(3);
    },

    // 原型img 获取当前index
    getCurrentIndex() {
      var hb5_img = document.getElementById("hb5_img");
      // console.log(hb5_img);

      var child = null;
      var findIndex = -1;
      for (var i = 0; i < hb5_img.children.length; i++) {
        child = hb5_img.children[i];
        if (child.nodeName != "IMG") {
          console.log("huaban5,发现节点类型不是img");
          continue;
        }
        console.log(child.style.opacity);
        if (child.style.opacity == 1) {
          findIndex = i;
          break;
        }
      }
      return findIndex;
    },
    // 圆形 img 区域设置 hover
    setValueByIndex(index, val1, val2) {
      var hb5_img = document.getElementById("hb5_img");
      var child = null;
      for (var i = 0; i < hb5_img.children.length; i++) {
        child = hb5_img.children[i];
        if (i == index) {
          child.style.opacity = val1;
        } else {
          child.style.opacity = val2;
        }
      }

      // 标题
      this.titleSetByIndex(index);

      // 按钮
      this.btnSetVauleByIndex(index);
    },

    // title 区域
    titleSetByIndex(index) {
      var titlePar = document.getElementById("hb5_title");
      var subArr = titlePar.children;
      var item = null;
      for (var i = 0; i < subArr.length; i++) {
        var item = subArr[i];
        if (i == index) {
          item.style.opacity = 1;
          item.style['z-index'] = 1;
        } else {
          item.style.opacity = 0;
          item.style['z-index'] = 0;
        }
      }
    },

    // btn 区域设置 hover
    btnSetVauleByIndex(index, val1, val2) {
      var btnArr = ["hb5_lb4", "hb5_lb5", "hb5_lb6", "hb5_lb7"];
      for (var i = 0; i < btnArr.length; i++) {
        var btnTar = document.getElementById(btnArr[i]);
        if (i == index) {
          // 因为第一个是灰色
          // 第二个是蓝色
          btnTar.children[0].style.opacity = 0;
          btnTar.children[1].style.opacity = 1;
        } else {
          btnTar.children[0].style.opacity = 1; // 显示灰色图标
          btnTar.children[1].style.opacity = 0; // 不显示蓝色图标
        }
      }

      // 按钮下面的文字
      var btnTextArr = [
        "text-group_2",
        "text-group_3",
        "text-group_4",
        "text-group_5",
      ];
      for (var i = 0; i < btnTextArr.length; i++) {
        var btnTarArr = document.getElementsByClassName(btnTextArr[i]);
        var btnTar = btnTarArr[0];
        if (i == index) {
          btnTar.style.color = "blue";
        } else {
          btnTar.style.color = "gray";
        }
      }
    },

    leftClick() {
      console.log("leftClick");
      var currentIndex = this.getCurrentIndex();
      // img
      if (currentIndex <= 0) {
        this.setValueByIndex(3, 1, 0);
      } else {
        this.setValueByIndex(--currentIndex, 1, 0);
      }
      // btn
    },

    rightClick() {
      console.log("rightClick");
      var currentIndex = this.getCurrentIndex();
      if (currentIndex >= 3) {
        this.setValueByIndex(0, 1, 0);
      } else {
        this.setValueByIndex(++currentIndex, 1, 0);
      }
    },
  },
};
</script>
