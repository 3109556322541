<template>
  <div class="page flex-col">
    <div class="box_1S flex-col" id="huaban0">
      <headerCom fontColor="color:white;" />
    </div>
    <div id="huaban3" class="huaban3">
      <huaban3 v-if="false" top="top:4rem" />
      <hunban3dyn></hunban3dyn>
    </div>
    <!-- style="
        background: url(./assets/img/psf6vypx8bgyd6mxl9v2e2xfdb1dlvneaejba0b591d-a135-44d9-bb5a-287c20b77857.431b8f40.png)
          100% no-repeat;
        padding-top: 50px;
      " -->
    <div id="huaban4">
      <huaban4 v-if="false" />
      <huaban4dyn />
    </div>

    <div id="huaban5">
      <huaban5dyn />
    </div>

    <div id="huaban2" style="
        background: url(/img/psqi9bveznwu6nagwyj7crji4yugk8r7rj72cef4bc-2068-4222-b742-3a0ba0111444.bc209ca0.png) -23px -9px
          no-repeat;
      ">
      <huaban2 />
    </div>

    <fotter />
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import huaban3 from "@/views/lanhu_huaban3/part.vue";
import hunban3dyn from "@/views/lanhu_huaban3/partDyn.vue";
import huaban4 from "@/views/lanhu_huaban4/part.vue";
import huaban4dyn from "@/views/lanhu_huaban4/partDyn.vue";
import huaban2 from "@/views/lanhu_huaban2/part.vue";
import huaban5dyn from "@/views/lanhu_huaban5/part.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    huaban3,
    hunban3dyn,
    huaban4,
    huaban4dyn,
    huaban2,
    huaban5dyn,
    fotter,
  },
  data() {
    return {
      constants: {},

      pageInfos: [
        {
          page: 1,
          min: 0,
          max: 1100,
          upAnchor: "0",
          downAnchor: "1100",
        },
        {
          page: 2,
          min: 1100,
          max: 2100,
          upAnchor: "0",
          downAnchor: "2100",
        },
        {
          page: 3,
          min: 2100,
          max: 3100,
          upAnchor: "1100",
          downAnchor: "3100",
        },
        {
          page: 4,
          min: 3100,
          max: 3200,
          upAnchor: "2100",
          downAnchor: "3200",
        },
      ],
      itv: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.id) {
        this.$scrollToTop(this.$route.query.id)
      }
    }, 500)
  },
  methods: {
    onClick() {
      var a = document.createElement("a");
      a.setAttribute("href", "#huaban3");
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    setScrollEvent() {
      
      // var that = this
      // window.onscroll = function () {
      //   var t = document.documentElement.scrollTop || document.body.scrollTop;
      //   console.log(t)
      //   that.getPageNum()
      // };

      var that = this;
      document.body.addEventListener(
        "onwheel" in document ? "wheel" : "mousewheel",
        function (e) {
          clearTimeout(that.itv);
          that.itv = setTimeout(function () {
            var target = that.getPageNum(that);
            var anchor = "";
            var delta = e.wheelDelta;
            if (delta == 120) {
              console.log("上");
              anchor = target.upAnchor;
            } else if (delta == -120) {
              anchor = target.downAnchor;
              console.log("下");
            }
            if (anchor == "") {
              return;
            }
            // console.log(anchor)
            // var a = document.createElement("a");
            // a.setAttribute("href", anchor);
            // document.body.appendChild(a);
            // a.click();
            // a.remove();
            that.scrollTop(target.min);
          }, 100);
          e.preventDefault();
        }
      );
    },
    getPageNum() {
      var currentTop = this.scrollTop();
      var pageInfo = null;
      for (var i = 0; i < this.pageInfos.length; i++) {
        pageInfo = this.pageInfos[i];
        if (currentTop >= pageInfo.min && currentTop <= pageInfo.max) {
          console.log("当前落在: " + pageInfo.page);
          break;
        }
      }
      return pageInfo;
    },
    //获取或设置scrollTop
    scrollTop(v) {
      var body = document.body;
      var html = document.documentElement;
      console.log("body.scrollTop" + body.scrollTop);
      console.log("html.scrollTop" + html.scrollTop);

      if (v == null) return Math.max(body.scrollTop, html.scrollTop);
      else body.scrollTop = html.scrollTop = v;
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
<style scoped lang="css">
#huaban4 {
  background: url(./assets/img/psf6vypx8bgyd6mxl9v2e2xfdb1dlvneaejba0b591d-a135-44d9-bb5a-287c20b77857.png) 100% no-repeat;
  padding-top: 50px;
}

#huaban2 {
  background: url(./assets/img/psqi9bveznwu6nagwyj7crji4yugk8r7rj72cef4bc-2068-4222-b742-3a0ba0111444.png) 100% no-repeat;
}
</style>