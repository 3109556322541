<template>
    <div class="section_4_card flex-row">
        <div class="group_5_card flex-col">
            <el-image style="width: 8.4rem; height: 8.4rem" :src="$url(formData.mainPicture)" fit="cover">
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
        </div>
        <div class="group_17_card flex-col">
            <div class="group_7_card flex-row">
                <div class="image-text_2_card flex-row justify-between">
                    <img class="thumbnail_1_card" referrerpolicy="no-referrer"
                        src="@/views/lanhu_identity_product/assets/img/identity.png" />
                    <span class="text-group_1_card">中国工业互联网标识服务中心&nbsp;&nbsp;|&nbsp;&nbsp;一物一码·真品溯源</span>
                </div>
            </div>
            <span class="text_3_card">{{ formData.name }}</span>
            <div class="group_8_card flex-col"></div>
            <div class="text-wrapper_1_card">
                <span class="text_4_card">产品分类&nbsp;&nbsp;</span>
                <span class="text_5_card">{{ formData.categoryName }}</span>
            </div>
            <div class="text-wrapper_2_card flex-col" @click="open">
                <span class="text_6_card">检测报告</span>
            </div>
            <el-image-viewer v-if="visible" :on-close="close" :url-list="$urlList(formData.certificate.split(','))"
                index="9999" />
            <div v-if="formData.productPictures" class="box_2_card flex-row justify-between">
                <div class="group_10_card flex-col" v-for="item in formData.productPictures.split(',')" :key="item">
                    <el-image :src="$urlIdentity(item)" style="width: 1.7rem;height: 1.7rem;"
                        :preview-src-list="[$urlIdentity(item)]" fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import router from "@/router";
export default {
    name: 'websiteCard',
    components: {
        ElImageViewer,
    },
    data() {
        return {
            visible: false
        };
    },
    props: {
        formData: {
            productPictures: []
        },
    },
    methods: {
        to() {
            router.push({ path: '/lanhu_identity_tenant' })
        },
        close() {
            this.visible = false
        },
        open() {
            if (this.formData.certificate && this.formData.certificate.split(',').length > 0){
                this.visible = true
            }else{
                this.$message.error('该产品暂未上传检测报告')
            }
                
        }
    }
}
</script>
<style scoped lang="css" >
.section_4_card {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 3px;
    width: 28rem;
    height: 10.4rem;
}

.group_5_card {
    background-color: rgba(255, 255, 255, 1);
    width: 8.48rem;
    height: 8.48rem;
    margin: 0.987rem 0 0 1.04rem;
}

.group_17_card {
    width: 18.08rem;
    height: 8.507rem;
    margin: 0.987rem 1.28rem 0 1.334rem;
}

.group_7_card {
    background-color: rgba(88, 112, 172, 0.13);
    border-radius: 5px;
    width: 10.134rem;
    height: 0.88rem;
    border: 1px solid rgba(222, 234, 255, 1);
}

.image-text_2_card {
    width: 9.467rem;
    height: 0.534rem;
    margin: 0.187rem 0 0 0.267rem;
}

.thumbnail_1_card {
    width: 0.507rem;
    height: 0.534rem;
}

.text-group_1_card {
    width: 8.747rem;
    height: 0.427rem;
    overflow-wrap: break-word;
    color: rgba(27, 49, 104, 1);
    font-size: 0.373rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
}

.text_3_card {
    width: 7.147rem;
    height: 0.694rem;
    overflow-wrap: break-word;
    color: rgba(37, 43, 58, 1);
    font-size: 0.72rem;
    font-family: PingFang-SC-Heavy;
    font-weight: 900;
    text-align: left;
    white-space: nowrap;
    margin: 0.614rem 0 0 0.054rem;
}

.group_8_card {
    background-color: rgba(234, 234, 234, 1);
    width: 17.947rem;
    height: 0.027rem;
    margin: 0.534rem 0 0 0.134rem;
}

.text-wrapper_1_card {
    width: 4.48rem;
    height: 0.374rem;
    overflow-wrap: break-word;
    font-size: 0;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 0.854rem;
    margin: 0.72rem 0 0 0.107rem;
}

.text_4_card {
    width: 4.48rem;
    height: 0.374rem;
    overflow-wrap: break-word;
    color: rgba(165, 165, 165, 1);
    font-size: 0.373rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 0.854rem;
}

.text_5_card {
    width: 4.48rem;
    height: 0.374rem;
    overflow-wrap: break-word;
    color: rgba(53, 53, 53, 1);
    font-size: 0.373rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 0.854rem;
}

.text-wrapper_2_card {
    height: 0.88rem;
    width: 2.214rem;
    margin: 0.8rem 0 0 0.134rem;
    border: 1px solid rgba(234, 234, 234, 1);
    ;
}

.text-wrapper_2_card:hover {
    border: 1px solid rgba(0, 100, 241, 1);
}

.text_6_card {
    width: 1.494rem;
    height: 0.374rem;
    overflow-wrap: break-word;
    color: rgba(53, 53, 53, 1);
    font-size: 0.373rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    margin: 0.16rem 0 0 0.307rem;
    cursor: pointer;
}

.text_6_card:hover {
    color: rgba(0, 95, 228, 1);
}

.box_2_card {
    width: 7.867rem;
    height: 1.707rem;
    margin: 0.8rem 0 0 0.107rem;
}

.group_10_card {
    background-color: rgba(255, 255, 255, 1);
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.1rem;
}

.group_10_card:hover {
    border: 1px solid rgba(0, 100, 241, 1);
}
</style>