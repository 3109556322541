const getters = {
  sidebar: state => state.app.sidebar,
  loginType: state => state.user.loginType,
  device: state => state.app.device,
  token: state => state.user.token,
  roleName: state => state.user.roleName,
  permission: state => state.user.permission,
  menu: state => state.user.menu,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userName: state => state.user.userName,
  personId: state => state.user.personId,
  person: state => state.user.person,
  school: state => state.user.school,
  department: state => state.user.department,
  departmentId: state => state.user.departmentId,
  developDept: state => state.user.developDept,
  dataDictionary: state => state.user.dataDictionary,
  id: state => state.user.id,
  receiveNum: state => state.user.receiveNum,
  sendNum: state => state.user.sendNum,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  departmentNewList: state => state.user.departmentNewList,
  linkBar: state => state.user.linkBar
}
export default getters
