<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="box_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云标识</span>
        <span class="text_4">标识解析&nbsp;二级节点</span>
      </div>
      <div id="content" class="box_8 flex-col">
        <div class="text-group_7 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;ID</span>
          <span class="text_7">云标识</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            随着数字化时代的到来，企业之间和企业内部的信息交流变得越来越频繁和复杂。
            <br />
          </span>
          <span class="text_8">云标识是由工信部直属-中国信息通信研究院推出</span>
          <span class="paragraph_2">
            ，支持兼容现有各种国际上主流的标识体系，提供公共查询入口和服务，是国家层面的标准化公共标识系统。真正实现产品上的一物一码。
            <br />
          </span>
        </div>
        <div class="block_8 flex-col">
          <div class="text-group_8 flex-col justify-between">
            <span class="text_9">Main&nbsp;advantages</span>
            <span class="text_10">主要优势</span>
          </div>
          <div class="section_2 flex-row justify-between">
            <div class="box_5 flex-col">
              <div class="image-text_4 flex-col justify-between">
                <img class="image_2" referrerpolicy="no-referrer"
                  src="./assets/img/pst4zlnxlrycem0iyjky17knl7bhbfiqvla5354c52-38c9-4bdd-b1fa-f693508d1fa2.png" />
                <span class="text-group_3">经济性</span>
              </div>
              <span class="text_11">
                云标识采用标准化方式与标识解析体系对接，避免了重复建设和无法互通的问题，从而实现全社会部署成本最低。此外，云标识可以发挥标识解析体系基础设施规模效应，降低了企业内及企业间信息交互的成本和复杂度。
              </span>
            </div>
            <div class="box_6 flex-col">
              <div class="image-text_5 flex-col justify-between">
                <img class="image_3" referrerpolicy="no-referrer"
                  src="./assets/img/ps8k2p59mly8j54k7z970q2fhgof2tpjx0t404fa8dc-2937-47e9-9e41-3598ce333d57.png" />
                <span class="text-group_4">安全性</span>
              </div>
              <span class="text_12">
                在保障企业自行掌控数据所有权的前提下，云标识通过标识解析系统发布数据位置，并按照实际业务需求，在产业链合作伙伴之间交互和分享数据。云标识还支持多层次的权限管理和加密传输，保障了数据的安全性。
              </span>
            </div>
            <div class="box_7 flex-col">
              <div class="image-text_6 flex-col justify-between">
                <img class="image_4" referrerpolicy="no-referrer"
                  src="./assets/img/psjq8m6xgizrijd4uzjnjtsrlqb17nxth52b3a5cc6-8889-4fdc-be7a-218f52b32181.png" />
                <span class="text-group_5">兼容性</span>
              </div>
              <span class="text_13">
                云标识可以利用标识解析节点系统服务，实现异构标识映射和转换，无需大规模取消原有标识，降低了转型改造的成本和复杂性。同时，云标识还支持多种标识格式和协议，可以与不同类型的应用系统进行对接。
              </span>
            </div>
          </div>
          <span class="text_14">
            以“搭平台、接应用、建生态”为主线，以工业互联网标识解析二级节点平台建设为基础，通过开放式对接各种业务应用，助力企业数字化建设，构建行业生态，赋能节点用户。
          </span>
        </div>
        <div class="text-group_9 flex-col justify-between">
          <span class="text_15">Identification&nbsp;analysis&nbsp;system</span>
          <span class="text_16">标识解析体系</span>
        </div>
        <div class="block_9 flex-row justify-between">
          <img class="image_5" referrerpolicy="no-referrer"
            src="./assets/img/psqez2iggjuzh3wvba1km1cpf6adppi5jrt7e75b264-9e59-47b2-8512-d6da83f6f0b9.png" />
          <div class="group_2 flex-col">
            <div class="text-wrapper_2 flex-col">
              <span class="text_17">国际根节点</span>
              <span class="text_18">面向全球范围不同国家、不同地区提供根区数据管理和根解析服务。</span>
            </div>
            <div class="text-wrapper_3 flex-col">
              <span class="text_19">国家顶级节点</span>
              <span class="text_20">与全球根节点互联，支持跨二级节点标识解析，支持异构标识解析体系互联。</span>
            </div>
            <div class="text-wrapper_4 flex-col">
              <span class="text_21">二级标识解析节点</span>
              <span class="text_22">面向行业提供标识注册和解析服务。</span>
            </div>
            <div class="text-wrapper_5 flex-col">
              <span class="text_23">企业标识解析节点</span>
              <span class="text_24">企业内部标识编码分配、管理，与公共标识解析系统对接。</span>
            </div>
          </div>
        </div>
        <img class="image_7" referrerpolicy="no-referrer"
          src="./assets/img/psbilsvqm1rhdhnm11b7q7mnlbiq10h8nnc93f37dcb-1093-47be-aaf2-0cb2f8c3edd8.png" />
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />