<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云地图</span>
        <span class="text_4">突出企业地理优势</span>
        
      </div>
      <div id="content" class="box_3 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;Business&nbsp;Card</span>
          <span class="text_7">云地图</span>
        </div>
        <span class="text_8">
          当今，随着工业制造行业的高速发展，云地图成为了一种不可或缺的工具。云地图是在线展示工业企业位置信息和企业信息的平台。它可以通过交互式地图技术来帮助用户获取关键性的数据和信息。
        </span>
        <div class="section_5 flex-col">
          <div class="text-wrapper_1 flex-row">
            <span class="text_9">在线展示工业企业位置信息</span>
            <span class="text_10">企业信息展示</span>
            <span class="text_11">周围企业查询</span>
          </div>
          <div class="image-wrapper_2 flex-row">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/pszn0j64g5049gfq2ue5tu67sl23r1tg3fc9b67ded0-6600-48b9-9462-1bf7cd6df957.png"
            />
          </div>
          <div class="image-wrapper_3 flex-row justify-between">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/psx1alrcvl8f8efb92epaah513nd2c8pgsnk21c11d9f-8a1e-484e-982d-bf465632d644.png"
            />
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/psujcbpi5icf89p5f22olmiv0rufzzap2f3b006c11d-d630-47ae-b496-1b7b94736979.png"
            />
          </div>
          <div class="text-wrapper_2 flex-row justify-between">
            <span class="text_12">
              通过云地图，您可以轻松查找工业企业的位置信息。这对于需要寻找供应商、客户以及物流伙伴的企业非常有用。云地图提供全球范围内的覆盖率，可以帮助用户准确地定位目标企业的位置，并且可以在线浏览其周边环境。
            </span>
            <span class="text_13">
              除了位置信息，云地图还可以提供企业的详细信息。这些信息包括企业名称、地址、联系方式等，可以让用户更好地了解该企业的业务范围、产品服务等重要信息。同时，云地图也可以显示企业的图片、视频等介绍资料，进一步增加企业展示的吸引力。
            </span>
            <span class="text_14">
              云地图通过整合大量数据源，提供了广泛、准确的企业信息可以帮助用户在特定位置附近快速找到他们感兴趣的注册企业，并查看更多详细信息，方便企业了解整合周边资源，形成创新联合体。
            </span>
          </div>
        </div>
        <div id="map" class="section_6 flex-col">
          <div class="block_1 flex-row">
            <div class="block_2 flex-col">
              <div class="group_1 flex-row">
                <el-tabs v-model="query.type" @tab-click="loadData()">
                  <el-tab-pane label="全部" name="全部"></el-tab-pane>
                  <el-tab-pane label="企业" name="企业"></el-tab-pane>
                  <el-tab-pane label="名片" name="名片"></el-tab-pane>
                </el-tabs>
                <div class="block_3 flex-col">
                  <el-row :gutter="1">
                    <el-col :span="20">
                      <el-input
                        v-model="query.keywords"
                        style="width: 100%"
                        placeholder="请输入关键字"
                        clearable
                        @change="loadData()"
                      ></el-input>
                    </el-col>
                    <el-col :span="4">
                      <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-search"
                        @click="loadData(true)"
                      ></el-button>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="block_4 flex-row">
              <div class="text-group_3 flex-col justify-between">
                <span class="text_18">云地图</span>
                <span class="text_19">突出企业地理优势</span>
              </div>
            </div>
          </div>
          <div class="block_5 flex-col">
            <div
              class="box_5 flex-row justify-between"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="image-wrapper_5 flex-col" @click="setCenter(item)">
                <img
                  class="image_5"
                  referrerpolicy="no-referrer"
                  :src="$url(item.logo)"
                />
              </div>
              <div
                class="box_6 flex-col justify-between"
                @click="setCenter(item)"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top"
                >
                  <span class="text_20"
                    ><el-tag v-if="item.type === '企业'" size="mini"
                      >企业</el-tag
                    >
                    <el-tag v-else size="mini" type="warning">名片</el-tag>
                    {{
                      item.name.length > 15
                        ? item.name.substring(0, 14) + "..."
                        : item.name
                    }}
                  </span>
                </el-tooltip>
                <div class="image-text_4 flex-row justify-between">
                  <img
                    class="thumbnail_4"
                    referrerpolicy="no-referrer"
                    src="./assets/img/location.png"
                  />
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.address"
                    placement="top"
                  >
                    <span class="text-group_6">{{
                      item.address.length > 15
                        ? item.address.substring(0, 14) + "..."
                        : item.address
                        ? item.address
                        : "暂无地理位置信息"
                    }}</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                small
                layout="prev, pager, next,total"
                :page-size="query.pageSize"
                :total="total"
                v-model="query.pn"
                @current-change="handlePageChange"
              />
            </div>
          </div>
        </div>
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import api from "./api.js";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      query: {
        // 三取一
        type: "全部",
        keywords: "",
        size: 8,
        pn: 1,
      },
      total: 0,
      visiable: false,
      map: {},
      AMap: {},
      list: {},
      marketList: [],
    };
  },
  mounted() {
    this.initMap();
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      api.list(this.query).then((res) => {
        if (res.code === 200) {
          this.list = res.data.list;
          this.total = res.data.total;
          this.query.pn = 1;
          this.query.latitude = res.data.list.latitude;
          this.query.longitude = res.data.list.longitude;
          this.loading = false;
          // this.setCenter(this.list[0]);
          this.setMarker();
        }
      });
    },
    initMap() {
      const _this = this;
      // 秘钥
      window._AMapSecurityConfig = {
        securityJsCode: "46470dc7ce53b3010117ae88203a9cb7",
      };
      // key
      AMapLoader.load({
        key: "ad87aae26247a8ed366641abc40d9549",
        version: "2.0",
      })
        .then((AMap) => {
          _this.map = new AMap.Map("map", {
            viewMode: "3D",
            zoom: 14,
            center: [121.629951, 38.920119],
          });
          AMap.plugin(
            [
              "AMap.ToolBar",
              "AMap.Scale",
              "AMap.HawkEye",
              "AMap.Geolocation",
              "AMap.MapType",
              "AMap.MouseTool",
              "AMap.Marker",
            ],
            function () {
              // AMap.plugin(['AMap.ToolBar', 'ElasticMarker', 'AMap.Scale', 'AMap.HawkEye', 'AMap.Geolocation', 'AMap.MapType', 'AMap.MouseTool'], function () {
              _this.map.addControl(new AMap.ToolBar());
              _this.map.addControl(new AMap.Scale());
              _this.map.addControl(new AMap.HawkEye());
              _this.map.addControl(new AMap.Geolocation());
              _this.map.addControl(new AMap.MapType());
              _this.map.addControl(new AMap.Marker());
              // map.addControl(new AMap.ElasticMarker())
              // const mouseTool = new AMap.MouseTool(map);手动绘制
              // mouseTool.measureArea();测量面积
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    setCenter(item) {
      console.log("setCenter");
       console.log(item);
      if (
        item.longitude &&
        item.longitude !== "0" &&
        item.latitude &&
        item.latitude !== "0"
      ) {
        this.map.setCenter([Number(item.longitude), Number(item.latitude)]);
      } else {
        // this.map.setCenter([121.629951, 38.920119]);

        this.$message({
          type: "error",
          message: "暂无定位信息！",
        });
      }
    },

    setMarker() {
      const _this = this;
      // 移除历史
      if (_this.marketList && _this.marketList.length >= 1) {
        _this.marketList.map((item) => {
          _this.map.remove(item);
        });
      }

      // init marker
      this.list.map((data) => {
        // eslint-disable-next-line no-undef
        const marker = new AMap.Marker({
          // eslint-disable-next-line no-undef
          position: new AMap.LngLat(
            Number(data.longitude),
            Number(data.latitude)
          ),
          title: data.name + "(" + data.address + ")",
        });
        _this.map.add(marker);
        _this.marketList.push(marker);
        console.log(_this.marketList);
      });
    },
    handlePageChange(pn) {
      this.query.pn = pn;
      this.loadData();
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
