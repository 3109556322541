<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <headerCom fontColor="color:black" />
      <div class="section_3 flex-col">
        <!-- <div class="box_1 flex-col"></div> -->
      </div>
      <part />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import part from "./part.vue";
export default {
  components: {
    headerCom,
    part,
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />