import 'vant/lib/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  Button,
  DropdownMenu,
  DropdownItem,
  Cell,
  Switch,
  Field
} from 'vant'
import {
  RadioGroup,
  Radio
} from 'vant';
import {
  CheckboxGroup,
  Checkbox
} from 'vant';
import {
  Tab,
  Tabs
} from 'vant';
import {
  Tabbar,
  TabbarItem
} from 'vant';
import {
  NavBar,
  Icon,
  Tag
} from 'vant';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueScrollTo from 'vue-scrollto'

Vue.component('van-field', Field)
Vue.component('van-button', Button)
Vue.component('van-dropdown-menu', DropdownMenu)
Vue.component('van-dropdown-item', DropdownItem)
Vue.component('van-cell', Cell)
Vue.component('van-switch', Switch)
Vue.component('van-checkbox-group', CheckboxGroup)
Vue.component('van-checkbox', Checkbox)
Vue.component('van-radio-group', RadioGroup)
Vue.component('van-radio', Radio)
Vue.component('van-tab', Tab)
Vue.component('van-tabs', Tabs)
Vue.component('van-tabbar', Tabbar)
Vue.component('van-tabbar-item', TabbarItem)
Vue.component('van-nav-bar', NavBar)
Vue.component('van-icon', Icon)
Vue.component('van-tag', Tag)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueScrollTo)

function scrollToTop(id) {
  const element = document.getElementById(id);
  const rect = element.getBoundingClientRect();
  const top = rect.top + window.pageYOffset;
  window.scrollTo({
    top: top,
    behavior: 'smooth'
  });
}

function urlRefresh(url){
  if (url && url.length && url.includes('http')) {
    // 如果已经有 http 或 https 前缀，直接返回原始字符串
    return url.replace('http://node472.com', 'https://www.jingyipt.cn/obs') + `?ts=${new Date().getTime()}`;
  } else {
    // 否则添加 http 前缀并返回
    return url ? 'https://www.jingyipt.com/source/' + url + `?ts=${new Date().getTime()}` : '';
  }
}

function url(url) {
  if (url && url.length && url.includes('http')) {
    // 如果已经有 http 或 https 前缀，直接返回原始字符串
    return url.replace('http://node472.com', 'https://www.jingyipt.cn/obs');
  } else {
    // 否则添加 http 前缀并返回
    return url ? 'https://www.jingyipt.com/source/' + url : '';
  }
}

function urlList(urlList) {
  for (var i = 0; i < urlList.length; i++) {
    urlList[i] = urlList[i].replace('http://node472.com', 'https://www.jingyipt.cn/obs')
  }
  return urlList;
}

function urlIdentity(url) {
  if (url && url.length && url.includes('http')) {
    // 如果已经有 http 或 https 前缀，直接返回原始字符串
    return url.replace('http://node472.com', 'https://www.jingyipt.cn/obs');
  } else {
    // 否则添加 http 前缀并返回
    return 'https://www.jingyipt.cn/obs/common/imges/noImg.png';
  }
}

function richUrl(richHtml) {
  if (richHtml) {
    return richHtml.replaceAll('<img src="/obs', '<img src="https://www.jingyipt.cn/obs')
  }
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
function handleTree(data, id, parentId, children) {
  let config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}

Vue.prototype.$urlList = urlList
Vue.prototype.$scrollToTop = scrollToTop
Vue.prototype.$url = url
Vue.prototype.$urlIdentity = urlIdentity
Vue.prototype.$richUrl = richUrl
Vue.prototype.$handleTree = handleTree
Vue.prototype.$urlRefresh = urlRefresh

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')